import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class ClientMapStateHandler {
  initClientMapScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.MapViewer_Client;
    callback(this.state);

    this.mapViewerApi
      .apiGrexmanagerMapviewerClientRegionRetrieve()
      .then((response) => {
        this.state.ClientMapScreen.outlineUri = response.outlineUri;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);
        this.state.ClientMapScreen.errorMessage =
          "Fout bij het laden van het klantgebied.";
        callback(this.state);
        setTimeout(() => {
          this.state.ClientMapScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
}
