import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import "./VersionRegionMapScreen.css";
// import ButtonList from "@shared/components/ButtonList";
// import Section from "../../components/Section";
// import { TextInput, TextInputType } from "@shared/components/TextInput";
// import DropDown from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
// import {
//   IndexToRoleEnum,
//   RoleEnumToDropDown,
//   RoleEnumToIndex,
//   dropDownListPrintItem,
//   formatDate,
// } from "@shared/utils/helperFunctions";
// import { RoleEnum } from "@shared/client/lib";
// import Alert from "@shared/components/Alert";
import Iframe from "react-iframe";

interface VersionRegionMapScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class VersionRegionMapScreen extends React.Component<VersionRegionMapScreenProps> {
  render() {
    return (
      <div id="VersionRegionMapScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Versie info",
              callback: () => {
                if (this.props.state.versionInfoScreen.versionId) {
                  this.props.stateHandler.loadVersionModelInfoScreen(
                    this.props.state.versionInfoScreen.versionId,
                    this.props.updateStateCallback
                  );
                }
              },
            },
            {
              title: "Gebied bewerken",
            },
          ]}
        ></BreadCrumbs>

        <div className="VersionRegionMapScreen-MapViewerHolder">
          {(() => {
            if (this.props.state.VersionRegionMapScreen.outlineUri) {
              return (
                <Iframe
                  url={this.props.state.VersionRegionMapScreen.outlineUri}
                  width="100%"
                  height="100%"
                />
              );
            }
          })()}
        </div>
      </div>
    );
  }
}
