/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexUserChangePassword
 */
export interface GrexUserChangePassword {
    /**
     * 
     * @type {string}
     * @memberof GrexUserChangePassword
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof GrexUserChangePassword
     */
    newPassword: string;
}

/**
 * Check if a given object implements the GrexUserChangePassword interface.
 */
export function instanceOfGrexUserChangePassword(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentPassword" in value;
    isInstance = isInstance && "newPassword" in value;

    return isInstance;
}

export function GrexUserChangePasswordFromJSON(json: any): GrexUserChangePassword {
    return GrexUserChangePasswordFromJSONTyped(json, false);
}

export function GrexUserChangePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserChangePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPassword': json['current_password'],
        'newPassword': json['new_password'],
    };
}

export function GrexUserChangePasswordToJSON(value?: GrexUserChangePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_password': value.currentPassword,
        'new_password': value.newPassword,
    };
}

