import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class LogStateHandler {
  initLogScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Logs;
    this.state.logScreen.logs = [];
    callback(this.state);

    // Get logs
    this.logApi
      .apiGrexmanagerLogRetrieve({
        logRows: 50,
        page: 1,
      })
      .then((response) => {
        this.state.logScreen.logs = response.logRows;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);
        this.state.logScreen.errorMessage = "Fout bij het laden van de logs.";
        callback(this.state);
        setTimeout(() => {
          this.state.logScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
}
