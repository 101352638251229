/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Model,
  ModelCreateRequest,
  ModelInfoResponse,
  ModelListResponse,
  ModelMoveRequest,
  ModelOpenResponse,
  ModelSessionEdit,
  ModelUpdateRequest,
  PatchedModelPartialUpdateRequest,
  SavedModelHistoryResponse,
  UploadSavedModelRequest,
} from '../models';
import {
    ModelFromJSON,
    ModelToJSON,
    ModelCreateRequestFromJSON,
    ModelCreateRequestToJSON,
    ModelInfoResponseFromJSON,
    ModelInfoResponseToJSON,
    ModelListResponseFromJSON,
    ModelListResponseToJSON,
    ModelMoveRequestFromJSON,
    ModelMoveRequestToJSON,
    ModelOpenResponseFromJSON,
    ModelOpenResponseToJSON,
    ModelSessionEditFromJSON,
    ModelSessionEditToJSON,
    ModelUpdateRequestFromJSON,
    ModelUpdateRequestToJSON,
    PatchedModelPartialUpdateRequestFromJSON,
    PatchedModelPartialUpdateRequestToJSON,
    SavedModelHistoryResponseFromJSON,
    SavedModelHistoryResponseToJSON,
    UploadSavedModelRequestFromJSON,
    UploadSavedModelRequestToJSON,
} from '../models';

export interface ApiGrexmanagerModelCreateRequest {
    modelCreateRequest: ModelCreateRequest;
}

export interface ApiGrexmanagerModelDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerModelDuplicateRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerModelListRequest {
    versionModelId?: number;
}

export interface ApiGrexmanagerModelMoveCreateRequest {
    id: number;
    modelMoveRequest: ModelMoveRequest;
}

export interface ApiGrexmanagerModelOpenRetrieveRequest {
    id: number;
    force?: boolean;
}

export interface ApiGrexmanagerModelPartialUpdateRequest {
    id: number;
    patchedModelPartialUpdateRequest?: PatchedModelPartialUpdateRequest;
}

export interface ApiGrexmanagerModelRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerModelSavedModelCreateRequest {
    id: number;
    uploadSavedModelRequest: UploadSavedModelRequest;
}

export interface ApiGrexmanagerModelSavedModelListRequest {
    id: number;
}

export interface ApiGrexmanagerModelSavedModelPartialUpdateRequest {
    id: number;
    savedModelId: number;
}

export interface ApiGrexmanagerModelSavedModelRetrieveRequest {
    id: number;
    savedModelId: number;
}

export interface ApiGrexmanagerModelSessionEditDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerModelSessionEditRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerModelUpdateRequest {
    id: number;
    modelUpdateRequest: ModelUpdateRequest;
}

/**
 * 
 */
export class ModelApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a nieuw model
     */
    async apiGrexmanagerModelCreateRaw(requestParameters: ApiGrexmanagerModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters.modelCreateRequest === null || requestParameters.modelCreateRequest === undefined) {
            throw new runtime.RequiredError('modelCreateRequest','Required parameter requestParameters.modelCreateRequest was null or undefined when calling apiGrexmanagerModelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelCreateRequestToJSON(requestParameters.modelCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a nieuw model
     */
    async apiGrexmanagerModelCreate(requestParameters: ApiGrexmanagerModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.apiGrexmanagerModelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a model
     */
    async apiGrexmanagerModelDestroyRaw(requestParameters: ApiGrexmanagerModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a model
     */
    async apiGrexmanagerModelDestroy(requestParameters: ApiGrexmanagerModelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerModelDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for duplicating a model
     */
    async apiGrexmanagerModelDuplicateRetrieveRaw(requestParameters: ApiGrexmanagerModelDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelDuplicateRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/duplicate/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for duplicating a model
     */
    async apiGrexmanagerModelDuplicateRetrieve(requestParameters: ApiGrexmanagerModelDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.apiGrexmanagerModelDuplicateRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a list of versions for a spicific version
     */
    async apiGrexmanagerModelListRaw(requestParameters: ApiGrexmanagerModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelListResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.versionModelId !== undefined) {
            queryParameters['version_model_id'] = requestParameters.versionModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of versions for a spicific version
     */
    async apiGrexmanagerModelList(requestParameters: ApiGrexmanagerModelListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelListResponse>> {
        const response = await this.apiGrexmanagerModelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for moving a model to a different version model
     */
    async apiGrexmanagerModelMoveCreateRaw(requestParameters: ApiGrexmanagerModelMoveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelMoveCreate.');
        }

        if (requestParameters.modelMoveRequest === null || requestParameters.modelMoveRequest === undefined) {
            throw new runtime.RequiredError('modelMoveRequest','Required parameter requestParameters.modelMoveRequest was null or undefined when calling apiGrexmanagerModelMoveCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/move/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelMoveRequestToJSON(requestParameters.modelMoveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for moving a model to a different version model
     */
    async apiGrexmanagerModelMoveCreate(requestParameters: ApiGrexmanagerModelMoveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.apiGrexmanagerModelMoveCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for opening a model
     */
    async apiGrexmanagerModelOpenRetrieveRaw(requestParameters: ApiGrexmanagerModelOpenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelOpenResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelOpenRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/open/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelOpenResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for opening a model
     */
    async apiGrexmanagerModelOpenRetrieve(requestParameters: ApiGrexmanagerModelOpenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelOpenResponse> {
        const response = await this.apiGrexmanagerModelOpenRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for partially updating model information
     */
    async apiGrexmanagerModelPartialUpdateRaw(requestParameters: ApiGrexmanagerModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedModelPartialUpdateRequestToJSON(requestParameters.patchedModelPartialUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for partially updating model information
     */
    async apiGrexmanagerModelPartialUpdate(requestParameters: ApiGrexmanagerModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.apiGrexmanagerModelPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving more detailed information about a model if you have access to it
     */
    async apiGrexmanagerModelRetrieveRaw(requestParameters: ApiGrexmanagerModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelInfoResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving more detailed information about a model if you have access to it
     */
    async apiGrexmanagerModelRetrieve(requestParameters: ApiGrexmanagerModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelInfoResponse> {
        const response = await this.apiGrexmanagerModelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for uploading a saved model file
     */
    async apiGrexmanagerModelSavedModelCreateRaw(requestParameters: ApiGrexmanagerModelSavedModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SavedModelHistoryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSavedModelCreate.');
        }

        if (requestParameters.uploadSavedModelRequest === null || requestParameters.uploadSavedModelRequest === undefined) {
            throw new runtime.RequiredError('uploadSavedModelRequest','Required parameter requestParameters.uploadSavedModelRequest was null or undefined when calling apiGrexmanagerModelSavedModelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/saved-model/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadSavedModelRequestToJSON(requestParameters.uploadSavedModelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedModelHistoryResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for uploading a saved model file
     */
    async apiGrexmanagerModelSavedModelCreate(requestParameters: ApiGrexmanagerModelSavedModelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SavedModelHistoryResponse> {
        const response = await this.apiGrexmanagerModelSavedModelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for lisiting the saved model history
     */
    async apiGrexmanagerModelSavedModelListRaw(requestParameters: ApiGrexmanagerModelSavedModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SavedModelHistoryResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSavedModelList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/saved-model/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SavedModelHistoryResponseFromJSON));
    }

    /**
     * Endpoint for lisiting the saved model history
     */
    async apiGrexmanagerModelSavedModelList(requestParameters: ApiGrexmanagerModelSavedModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SavedModelHistoryResponse>> {
        const response = await this.apiGrexmanagerModelSavedModelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for activating a saved model
     */
    async apiGrexmanagerModelSavedModelPartialUpdateRaw(requestParameters: ApiGrexmanagerModelSavedModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SavedModelHistoryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSavedModelPartialUpdate.');
        }

        if (requestParameters.savedModelId === null || requestParameters.savedModelId === undefined) {
            throw new runtime.RequiredError('savedModelId','Required parameter requestParameters.savedModelId was null or undefined when calling apiGrexmanagerModelSavedModelPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/saved-model/{saved_model_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"saved_model_id"}}`, encodeURIComponent(String(requestParameters.savedModelId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedModelHistoryResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for activating a saved model
     */
    async apiGrexmanagerModelSavedModelPartialUpdate(requestParameters: ApiGrexmanagerModelSavedModelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SavedModelHistoryResponse> {
        const response = await this.apiGrexmanagerModelSavedModelPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for downloading a saved model file
     */
    async apiGrexmanagerModelSavedModelRetrieveRaw(requestParameters: ApiGrexmanagerModelSavedModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSavedModelRetrieve.');
        }

        if (requestParameters.savedModelId === null || requestParameters.savedModelId === undefined) {
            throw new runtime.RequiredError('savedModelId','Required parameter requestParameters.savedModelId was null or undefined when calling apiGrexmanagerModelSavedModelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/saved-model/{saved_model_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"saved_model_id"}}`, encodeURIComponent(String(requestParameters.savedModelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Endpoint for downloading a saved model file
     */
    async apiGrexmanagerModelSavedModelRetrieve(requestParameters: ApiGrexmanagerModelSavedModelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiGrexmanagerModelSavedModelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a model\'s edit session
     */
    async apiGrexmanagerModelSessionEditDestroyRaw(requestParameters: ApiGrexmanagerModelSessionEditDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSessionEditDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/session-edit/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a model\'s edit session
     */
    async apiGrexmanagerModelSessionEditDestroy(requestParameters: ApiGrexmanagerModelSessionEditDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerModelSessionEditDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving information about a model\'s edit session
     */
    async apiGrexmanagerModelSessionEditRetrieveRaw(requestParameters: ApiGrexmanagerModelSessionEditRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelSessionEdit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelSessionEditRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/session-edit/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelSessionEditFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving information about a model\'s edit session
     */
    async apiGrexmanagerModelSessionEditRetrieve(requestParameters: ApiGrexmanagerModelSessionEditRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelSessionEdit> {
        const response = await this.apiGrexmanagerModelSessionEditRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating model information
     */
    async apiGrexmanagerModelUpdateRaw(requestParameters: ApiGrexmanagerModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Model>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerModelUpdate.');
        }

        if (requestParameters.modelUpdateRequest === null || requestParameters.modelUpdateRequest === undefined) {
            throw new runtime.RequiredError('modelUpdateRequest','Required parameter requestParameters.modelUpdateRequest was null or undefined when calling apiGrexmanagerModelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/model/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModelUpdateRequestToJSON(requestParameters.modelUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating model information
     */
    async apiGrexmanagerModelUpdate(requestParameters: ApiGrexmanagerModelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Model> {
        const response = await this.apiGrexmanagerModelUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
