import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class BiLinksStateHandler {
  initBiLinksScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.BiLinks;
    callback(this.state);
    this.linkBiApi
      .apiGrexbiV1ReportList()
      .then((response) => {
        this.state.BiLinksScreen.biLinks = response;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.BiLinksScreen.errorMessage =
          "Fout bij het laden van BI links.";
        callback(this.state);
        setTimeout(() => {
          this.state.BiLinksScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  biLinksScreenGenerateTemporaryCredentials(
    this: AppStateHandler,
    biLinkUuid: string,
    callback: (newState: AppStateType) => void
  ) {
    console.log(biLinkUuid);

    this.linkBiApi
      .apiGrexbiV1ReportPasswordCreate({ uuid: biLinkUuid })
      .then((response) => {
        const linkObjectIndex = this.state.BiLinksScreen.biLinks.findIndex(
          (object) => {
            return object.uuid === biLinkUuid;
          }
        );
        if (linkObjectIndex > -1) {
          this.state.BiLinksScreen.biLinks[linkObjectIndex].password =
            response.password;
          callback(this.state);
        }
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.BiLinksScreen.errorMessage =
          "Fout bij het aanmaken van een tijdelijk wachtwoord.";
        callback(this.state);
        setTimeout(() => {
          this.state.BiLinksScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  biLinksScreenDeleteLink(
    this: AppStateHandler,
    biLinkUuid: string,
    callback: (newState: AppStateType) => void
  ) {
    console.log(biLinkUuid);

    this.linkBiApi
      .apiGrexbiV1ReportDestroy({ uuid: biLinkUuid })
      .then((response) => {
        const linkObjectIndex = this.state.BiLinksScreen.biLinks.findIndex(
          (object) => {
            return object.uuid === biLinkUuid;
          }
        );
        if (linkObjectIndex > -1) {
          // remove the object from the array
          this.state.BiLinksScreen.biLinks.splice(linkObjectIndex, 1);
          callback(this.state);
        }
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.BiLinksScreen.errorMessage =
          "Fout bij het verwijderen van de BI link.";
        callback(this.state);
        setTimeout(() => {
          this.state.BiLinksScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
}

// function makeid(length: number) {
//   let result = "";
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   const charactersLength = characters.length;
//   let counter = 0;
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     counter += 1;
//   }
//   return result;
// }
