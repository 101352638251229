import { TypeEnum } from "@shared/client/lib";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import FileInput from "@shared/components/FileInput";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  AttachmentTypeToDropDown,
  AttachmentTypeToIndex,
  IndexToAttachmentType,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./VersionModelNewAttachmentScreen.css";

interface VersionModelNewAttachmentScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class VersionModelNewAttachmentScreen extends React.Component<VersionModelNewAttachmentScreenProps> {
  render() {
    return (
      <div id="VersionModelNewAttachmentScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Versie info",
              callback: () => {
                if (this.props.state.projectScreen.selectedVersionModel) {
                  this.props.stateHandler.loadVersionModelInfoScreen(
                    this.props.state.projectScreen.selectedVersionModel,
                    this.props.updateStateCallback
                  );
                }
              },
            },
            {
              title: "Bijlage toevoegen",
            },
          ]}
        ></BreadCrumbs>

        <Section
          disabled={this.props.state.versionInfoNewAttachmentScreen.loadingForm}
        >
          {(() => {
            if (
              this.props.state.versionInfoNewAttachmentScreen.formError !== ""
            ) {
              return (
                <Alert
                  Error={true}
                  Message={
                    this.props.state.versionInfoNewAttachmentScreen.formError
                  }
                ></Alert>
              );
            }
          })()}
          <table className="VersionModelNewAttachmentScreen-Table">
            <tbody>
              <tr>
                <td>Bijlagecategorie*</td>
                <td>
                  <DropDown
                    list={
                      this.props.state.versionInfoNewAttachmentScreen
                        .categoryAttachments
                    }
                    onChange={(newKey) => {
                      this.props.stateHandler.updateVersionModelNewAttachmentScreen(
                        { categoryAttachment: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={
                      this.props.state.versionInfoNewAttachmentScreen
                        .categoryAttachment
                    }
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <td>Type bijlage*</td>
                <td>
                  <DropDown
                    list={AttachmentTypeToDropDown()}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.updateVersionModelNewAttachmentScreen(
                        { attachmentType: IndexToAttachmentType(newKey) },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={AttachmentTypeToIndex(
                      this.props.state.versionInfoNewAttachmentScreen
                        .attachmentType
                    )}
                  ></DropDown>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        {(() => {
          if (
            this.props.state.versionInfoNewAttachmentScreen.attachmentType ===
            TypeEnum.File
          ) {
            return (
              <Section
                disabled={
                  this.props.state.versionInfoNewAttachmentScreen.loadingForm
                }
              >
                <table className="VersionModelNewAttachmentScreen-Table">
                  <tbody>
                    <tr>
                      <td>Bestand*</td>
                      <td>
                        <FileInput
                          onChange={(event) => {
                            this.props.stateHandler.updateVersionModelNewAttachmentScreen(
                              { files: event.target.files },
                              this.props.updateStateCallback
                            );
                          }}
                        ></FileInput>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Section>
            );
          } else if (
            this.props.state.versionInfoNewAttachmentScreen.attachmentType ===
            TypeEnum.Link
          ) {
            return (
              <Section
                disabled={
                  this.props.state.versionInfoNewAttachmentScreen.loadingForm
                }
              >
                <table className="VersionModelNewAttachmentScreen-Table">
                  <tbody>
                    <tr>
                      <td>Link URL*</td>
                      <td>
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="URL"
                          onChange={(newValue) => {
                            this.props.stateHandler.updateVersionModelNewAttachmentScreen(
                              { linkUrl: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={
                            this.props.state.versionInfoNewAttachmentScreen
                              .linkUrl || ""
                          }
                        ></TextInput>
                      </td>
                    </tr>
                    <tr>
                      <td>Zichtbare naam*</td>
                      <td>
                        <TextInput
                          type={TextInputType.Text}
                          placeholder="Zichtbare naam"
                          onChange={(newValue) => {
                            this.props.stateHandler.updateVersionModelNewAttachmentScreen(
                              { displayName: newValue },
                              this.props.updateStateCallback
                            );
                          }}
                          value={
                            this.props.state.versionInfoNewAttachmentScreen
                              .displayName || ""
                          }
                        ></TextInput>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Section>
            );
          }
        })()}

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.saveVersionModelNewAttachmentScreen(
                  this.props.updateStateCallback
                );
              },
              disabled:
                this.props.state.versionInfoNewAttachmentScreen.loadingForm,
            },
            {
              title: "Annuleren",
              callback: () => {
                if (this.props.state.projectScreen.selectedVersionModel) {
                  this.props.stateHandler.loadVersionModelInfoScreen(
                    this.props.state.projectScreen.selectedVersionModel,
                    this.props.updateStateCallback
                  );
                }
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
