import React from "react";
import "./TextButton.css";

interface TextButtonProps {
  onClick?: () => void;
  text: string;
}

class TextButton extends React.Component<TextButtonProps> {
  render() {
    return (
      <div className="TextButton">
        <p onClick={this.props.onClick}>{this.props.text}</p>
      </div>
    );
  }
}

export default TextButton;
