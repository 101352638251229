import Alert from "@shared/components/Alert";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";
import "./ResetPasswordMfaScreen.css";
interface ResetPasswordMfaScreenProps {
  state: AppStateType;
  loginstate: LoginState;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ResetPasswordMfaScreen extends React.Component<ResetPasswordMfaScreenProps> {
  render() {
    return (
      <div id="ResetPasswordMfaScreen-Container">
        <div id="ResetPasswordMfaScreen-Section">
          <GrexManagerLogo />

          <div id="ResetPasswordMfaScreen-ResetPasswordMfaHolder">
            <SectionTitle>Multi-Factor Authenticatie</SectionTitle>
            <p>
              Vul de code in die u heeft ontvangen via de Authenticator app.
            </p>
            {(() => {
              if (this.props.state.ResetPassword.resetpasswordMessage !== "") {
                return (
                  <Alert
                    Error={true}
                    Message={
                      this.props.state.ResetPassword.resetpasswordMessage
                    }
                  ></Alert>
                );
              }
            })()}
            <div className="label">MFA Code</div>
            <TextInput
              type={TextInputType.Text}
              placeholder="XXXXXX"
              value={this.props.state.ResetPassword.mfaInput}
              onChange={(newValue: string) => {
                this.props.stateHandler.ResetPasswordVerifyMfaCode(
                  newValue,
                  this.props.updateStateCallback
                );
              }}
            ></TextInput>
          </div>
        </div>
      </div>
    );
  }
}
