import Alert from "@shared/components/Alert";
import ButtonList from "@shared/components/ButtonList";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";
import "./ResetPasswordEmailScreen.css";
interface ResetPasswordEmailScreenProps {
  state: AppStateType;
  loginstate: LoginState;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ResetPasswordEmailScreen extends React.Component<ResetPasswordEmailScreenProps> {
  render() {
    return (
      <div id="ResetPasswordEmailScreen-Container">
        <div id="ResetPasswordEmailScreen-Section">
          <GrexManagerLogo />

          <div id="ResetPasswordEmailScreen-LoginMfaHolder">
            <SectionTitle>Stel uw wachtwoord opnieuw in</SectionTitle>
            <p>
              Voer uw e-mailadres in en we sturen u instructies om uw wachtwoord
              opnieuw in te stellen.
            </p>

            {(() => {
              if (this.props.state.ResetPassword.resetpasswordMessage !== "") {
                return (
                  <Alert
                    Error={true}
                    Message={
                      this.props.state.ResetPassword.resetpasswordMessage
                    }
                  ></Alert>
                );
              }
            })()}

            <div className="label">E-mailadres</div>
            <TextInput
              type={TextInputType.Email}
              placeholder="Uw e-mailadres"
              value={this.props.state.ResetPassword.email}
              onChange={(newValue: string) => {
                this.props.stateHandler.ResetPasswordSendEmailForm(
                  newValue,
                  this.props.updateStateCallback
                );
              }}
            ></TextInput>
            <div className="ResetPasswordEmailScreen-buttonbox">
              <ButtonList
                data={[
                  {
                    title: "Versturen",
                    callback: () => {
                      this.props.stateHandler.ResetPasswordSendEmail(
                        this.props.state.ResetPassword.email,
                        this.props.updateStateCallback
                      );
                    },
                  },
                  {
                    title: "Annuleren",
                    callback: () => {
                      this.props.stateHandler.changeLoginScreen(
                        LoginState.LoginEmailPassword,
                        this.props.updateStateCallback
                      );
                    },
                  },
                ]}
              ></ButtonList>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
