import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, LoginState } from "src/Types";

export class LoginSamlStateHandler {
  async login_saml(this: AppStateHandler) {
    window.location.href = `${this.basePath()}/api/grexmanager/saml/sign-in/`;
  }

  go_to_login(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.loginState = LoginState.LoginEmailPassword;
    callback(this.state);
  }
}
