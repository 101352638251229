import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class PhaseStateHandler {
  loadPhaseList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Configuration_PhaseDefinitions;
    this.state.phaseScreen.phasesList = [];
    this.state.phaseScreen.newPhase = "";

    callback(this.state);
    this.phaseApi
      .apiGrexmanagerPhaseList()
      .then((phases) => {
        this.state.phaseScreen.phasesList = phases.map((phase) => {
          return {
            id: phase.id,
            description: phase.description || "",
            newDescription: phase.description || "",
            saveButtonVisable: false,
          };
        });
        callback(this.state);
      })
      .catch((error) => {
        // Error while loading phases
        this.state.phaseScreen.formError =
          "Fout bij het laden van fasedefinities";
        callback(this.state);
        setTimeout(() => {
          this.state.phaseScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onPhaseDeleteClick(
    this: AppStateHandler,
    phaseId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.phaseApi
      .apiGrexmanagerPhaseDestroyRaw({ id: phaseId })
      .then(() => {
        this.loadPhaseList(callback);
      })
      .catch((error) => {
        // Error while deleting phase
        this.state.phaseScreen.formError =
          "Fout bij het verwijderen van fasedefinitie.";
        callback(this.state);
        setTimeout(() => {
          this.state.phaseScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onPhaseEditInputChange(
    this: AppStateHandler,
    phaseId: number,
    newDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    const phaseIndex = this.state.phaseScreen.phasesList.findIndex((phase) => {
      return phase.id === phaseId;
    });
    if (phaseIndex > -1) {
      this.state.phaseScreen.phasesList[phaseIndex].newDescription =
        newDescription;
      if (newDescription === "") {
        this.state.phaseScreen.phasesList[phaseIndex].saveButtonVisable = false;
      } else {
        this.state.phaseScreen.phasesList[phaseIndex].saveButtonVisable = true;
      }
      callback(this.state);
    }
  }

  onPhaseEditClick(
    this: AppStateHandler,
    phaseId: number,
    phaseDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    this.phaseApi
      .apiGrexmanagerPhaseUpdate({
        id: phaseId,
        phase: {
          description: phaseDescription,
        },
      })
      .then(() => {
        // Hide save button
        const phaseIndex = this.state.phaseScreen.phasesList.findIndex(
          (phase) => {
            return phase.id === phaseId;
          }
        );
        if (phaseIndex > -1) {
          this.state.phaseScreen.phasesList[phaseIndex].saveButtonVisable =
            false;
          callback(this.state);
        }

        this.loadPhaseList(callback);
      })
      .catch((error) => {
        // Message in the frontend
        let message = "Er is een fout opgetreden tijdens het opslaan.";
        if (error.response.status === 409) {
          message = "Er bestaat al een fasedefinitie met deze naam.";
        }
        this.state.phaseScreen.formError = message;
        callback(this.state);
        setTimeout(() => {
          this.state.phaseScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onNewPhaseInputChange(
    this: AppStateHandler,
    newPhase: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.phaseScreen.newPhase = newPhase;
    callback(this.state);
  }

  onPhaseAddClick(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.phaseScreen.newPhase !== "") {
      this.phaseApi
        .apiGrexmanagerPhaseCreate({
          phase: {
            description: this.state.phaseScreen.newPhase,
          },
        })
        .then(() => {
          this.state.phaseScreen.newPhase = "";
          callback(this.state);
          this.loadPhaseList(callback);
        })
        .catch((error) => {
          // Message in the frontend
          let message = "Er is een fout opgetreden tijdens het opslaan.";
          if (error.response.status === 409) {
            message = "Er bestaat al een fasedefinitie met deze naam.";
          }
          this.state.phaseScreen.formError = message;
          callback(this.state);
          setTimeout(() => {
            this.state.phaseScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
