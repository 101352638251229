import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class NewBiLinkStateHandler {
  initNewBiLinkScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.reportScreen.selectedModels.length > 0) {
      this.state.screenState = ScreenState.NewBiLink;
      this.state.NewBiLinkScreen = {
        formError: "",
        description: "",
        selectedModels: this.state.reportScreen.selectedModels,
      };
      callback(this.state);
    }
  }

  newBiLinkUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      description?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.description !== undefined) {
      this.state.NewBiLinkScreen.description = newFormValues.description;
    }
    callback(this.state);
  }

  newBiLinkSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    console.log(this.state.NewBiLinkScreen);

    if (
      this.state.NewBiLinkScreen.description === "" ||
      this.state.NewBiLinkScreen.selectedModels.length === 0
    ) {
      this.state.NewBiLinkScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.NewBiLinkScreen.formError = "";
        callback(this.state);
      }, 2000);
    } else {
      this.linkBiApi
        .apiGrexbiV1ReportCreate({
          linkBICreateRequest: {
            description: this.state.NewBiLinkScreen.description,
            models: this.state.NewBiLinkScreen.selectedModels.map(
              (x) => x.model
            ),
          },
        })
        .then((response) => {
          this.changeScreen(ScreenState.BiLinks, callback);
        })
        .catch((error) => {
          console.log(error);

          this.state.NewBiLinkScreen.formError =
            "Error tijdens het aanmaken van de BI-link.";
          callback(this.state);
          setTimeout(() => {
            this.state.NewBiLinkScreen.formError = "";
            callback(this.state);
          }, 2000);
        });
    }
  }
}
