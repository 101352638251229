import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import { formatDate } from "@shared/utils/helperFunctions";
import React from "react";
import { AiOutlineDelete, AiOutlineReload } from "react-icons/ai";
import { FiCopy } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./BiLinksScreen.css";

interface BiLinksScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class BiLinksScreen extends React.Component<BiLinksScreenProps> {
  render() {
    return (
      <div id="BiLinksScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Rapportages",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Reports,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "BI Links",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.BiLinksScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.BiLinksScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        <Section>
          <table className="BiLinksScreen-Table">
            <thead>
              <tr>
                <th>Aangemaakt op</th>
                <th>Beschrijving</th>
                <th>BI link</th>
                <th></th>
                <th>Tijdelijke gebruikersnaam/wachtwoord</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.BiLinksScreen.biLinks.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{formatDate(item.timestampCreated)}</td>
                    <td>
                      <span className="BiLinksScreen-ScrollableText">
                        {item.description}
                      </span>
                    </td>
                    <td>
                      <span className="BiLinksScreen-ScrollableText">
                        {item.url}
                      </span>
                    </td>
                    <td>
                      <FiCopy
                        className="BiLinksScreen-Button"
                        onClick={() => navigator.clipboard.writeText(item.url)}
                      />
                    </td>
                    <td>
                      {(() => {
                        if (item.password) {
                          return (
                            <table className="BiLinksScreen-Subtable">
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="BiLinksScreen-ScrollableText">
                                      {"grexmanager"}
                                    </span>
                                  </td>
                                  <td>
                                    <FiCopy
                                      className="BiLinksScreen-Button"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          "grexmanager"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <span className="BiLinksScreen-ScrollableText">
                                      {item.password}
                                    </span>
                                  </td>
                                  <td>
                                    <FiCopy
                                      className="BiLinksScreen-Button"
                                      onClick={() => {
                                        if (item.password) {
                                          navigator.clipboard.writeText(
                                            item.password
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <AiOutlineReload
                                      className="BiLinksScreen-Button"
                                      onClick={() => {
                                        this.props.stateHandler.biLinksScreenGenerateTemporaryCredentials(
                                          item.uuid,
                                          this.props.updateStateCallback
                                        );
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() => {
                                this.props.stateHandler.biLinksScreenGenerateTemporaryCredentials(
                                  item.uuid,
                                  this.props.updateStateCallback
                                );
                              }}
                            >
                              Genereer
                            </Button>
                          );
                        }
                      })()}
                    </td>
                    <td>
                      <AiOutlineDelete
                        className="BiLinksScreen-Button"
                        onClick={() => {
                          this.props.stateHandler.biLinksScreenDeleteLink(
                            item.uuid,
                            this.props.updateStateCallback
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>
      </div>
    );
  }
}
