/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedModelPartialUpdateRequest
 */
export interface PatchedModelPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedModelPartialUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedModelPartialUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedModelPartialUpdateRequest
     */
    frozen?: boolean;
}

/**
 * Check if a given object implements the PatchedModelPartialUpdateRequest interface.
 */
export function instanceOfPatchedModelPartialUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedModelPartialUpdateRequestFromJSON(json: any): PatchedModelPartialUpdateRequest {
    return PatchedModelPartialUpdateRequestFromJSONTyped(json, false);
}

export function PatchedModelPartialUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedModelPartialUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'frozen': !exists(json, 'frozen') ? undefined : json['frozen'],
    };
}

export function PatchedModelPartialUpdateRequestToJSON(value?: PatchedModelPartialUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'frozen': value.frozen,
    };
}

