import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";

export class ResetPasswordCodeStateHandler {
  ResetPasswordVerifyCodeForm(
    this: AppStateHandler,
    code: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.ResetPassword.resetPasswordCode = code;
    callback(this.state);
  }

  ResetPasswordVerifyCode(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.authApi
      .apiGrexmanagerUserResetPasswordCheckTokenCreate({
        resetPasswordCheckToken: {
          resetPasswordToken: this.state.ResetPassword.resetPasswordCode,
          email: this.state.ResetPassword.email,
        },
      })
      .then((response: any) => {
        if (response.token === true) {
          if (response.mfa === true) {
            this.state.ResetPassword.mfaenabled = response.mfa;
            this.state.ResetPassword.resetpasswordMessage = "";
            this.changeLoginScreen(LoginState.ResetPasswordMFA, callback);
          } else {
            this.state.ResetPassword.resetpasswordMessage = "";
            this.changeLoginScreen(
              LoginState.ResetPasswordNewPassword,
              callback
            );
          }
        } else {
          this.state.ResetPassword.resetpasswordMessage =
            "De ingevoerde code is ongeldig";
          callback(this.state);
        }
      })
      .catch((error: any) => {
        this.state.ResetPassword.resetpasswordMessage =
          "De ingevoerde code is ongeldig";
        callback(this.state);
      });
  }
}
