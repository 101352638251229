/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubregionRetrieveResponse
 */
export interface SubregionRetrieveResponse {
    /**
     * 
     * @type {number}
     * @memberof SubregionRetrieveResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SubregionRetrieveResponse
     */
    description: string;
}

/**
 * Check if a given object implements the SubregionRetrieveResponse interface.
 */
export function instanceOfSubregionRetrieveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function SubregionRetrieveResponseFromJSON(json: any): SubregionRetrieveResponse {
    return SubregionRetrieveResponseFromJSONTyped(json, false);
}

export function SubregionRetrieveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubregionRetrieveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
    };
}

export function SubregionRetrieveResponseToJSON(value?: SubregionRetrieveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
    };
}

