import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import "./OverviewMapScreen.css";
// import ButtonList from "@shared/components/ButtonList";
// import Section from "../../components/Section";
// import { TextInput, TextInputType } from "@shared/components/TextInput";
// import DropDown from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
// import {
//   IndexToRoleEnum,
//   RoleEnumToDropDown,
//   RoleEnumToIndex,
//   dropDownListPrintItem,
//   formatDate,
// } from "@shared/utils/helperFunctions";
// import { RoleEnum } from "@shared/client/lib";
// import Alert from "@shared/components/Alert";
import Alert from "@shared/components/Alert";
import ButtonList from "@shared/components/ButtonList";
import Iframe from "react-iframe";

interface OverviewMapScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class OverviewMapScreen extends React.Component<OverviewMapScreenProps> {
  render() {
    return (
      <div id="OverviewMapScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Overzichtskaart",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.OverviewMapScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.OverviewMapScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        <div className="OverviewMapScreen-MapViewerHolder">
          {(() => {
            if (this.props.state.OverviewMapScreen.viewerUri) {
              return (
                <Iframe
                  url={this.props.state.OverviewMapScreen.viewerUri}
                  width="100%"
                  height="100%"
                />
              );
            }
          })()}
        </div>

        <div style={{ marginTop: "12px" }}>
          <ButtonList
            data={[
              {
                title: "Bekijk lijst",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.Projects,
                    this.props.updateStateCallback
                  );
                },
                disabled: false,
              },
            ]}
          ></ButtonList>
        </div>
      </div>
    );
  }
}
