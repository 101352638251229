import { TypeEnum } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class VersionModelNewAttachmentStateHandler {
  loadVersionModelNewAttachmentScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState =
      ScreenState.Projects_VersionModelInfo_NewAttachment;

    this.state.versionInfoNewAttachmentScreen = {
      formError: "",
      loadingForm: true,
      attachmentType: TypeEnum.File,
      categoryAttachments: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      categoryAttachment: 0,
      linkUrl: null,
      displayName: null,
      file: null,
    };
    callback(this.state);

    if (this.state.projectScreen.selectedVersionModel) {
      // Get categoryAttachments
      this.categoryAttachmentApi
        .apiGrexmanagerCategoryAttachmentList()
        .then((categoryAttachments) => {
          this.state.versionInfoNewAttachmentScreen.categoryAttachments =
            categoryAttachments.map((categoryAttachment) => {
              return {
                key: categoryAttachment.id,
                name: categoryAttachment.name || "",
                disabled: false,
              };
            });

          if (
            this.state.versionInfoNewAttachmentScreen.categoryAttachments
              .length > 0
          ) {
            this.state.versionInfoNewAttachmentScreen.categoryAttachment =
              this.state.versionInfoNewAttachmentScreen.categoryAttachments[0].key;
          }
          this.state.versionInfoNewAttachmentScreen.loadingForm = false;
          callback(this.state);
        })
        .catch((error) => {
          // TODO: Handle error
          console.log(error);

          this.state.versionInfoNewAttachmentScreen.formError =
            "Fout bij het laden van bijlagecategorieën.";
          callback(this.state);
          setTimeout(() => {
            this.state.versionInfoNewAttachmentScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  updateVersionModelNewAttachmentScreen(
    this: AppStateHandler,
    newFormValues: {
      categoryAttachment?: number;
      attachmentType?: TypeEnum;
      displayName?: string;
      linkUrl?: string;
      files?: FileList | null;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.categoryAttachment !== undefined) {
      this.state.versionInfoNewAttachmentScreen.categoryAttachment =
        newFormValues.categoryAttachment;
    }
    if (newFormValues.attachmentType !== undefined) {
      this.state.versionInfoNewAttachmentScreen.attachmentType =
        newFormValues.attachmentType;
    }
    if (newFormValues.displayName !== undefined) {
      this.state.versionInfoNewAttachmentScreen.displayName =
        newFormValues.displayName;
    }
    if (newFormValues.linkUrl !== undefined) {
      this.state.versionInfoNewAttachmentScreen.linkUrl = newFormValues.linkUrl;
    }
    if (newFormValues.files !== undefined) {
      if (newFormValues.files !== null) {
        this.state.versionInfoNewAttachmentScreen.file = newFormValues.files[0];
      }
    }
    callback(this.state);
  }

  saveVersionModelNewAttachmentScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.projectScreen.selectedVersionModel) {
      if (this.state.versionInfoNewAttachmentScreen.categoryAttachment > 0) {
        if (
          this.state.versionInfoNewAttachmentScreen.attachmentType ===
          TypeEnum.Link
        ) {
          if (this.state.versionInfoNewAttachmentScreen.displayName === null) {
            this.state.versionInfoNewAttachmentScreen.formError =
              "Voer een naam in.";
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoNewAttachmentScreen.formError = "";
              callback(this.state);
            }, 5000);
          } else if (
            this.state.versionInfoNewAttachmentScreen.linkUrl === null
          ) {
            this.state.versionInfoNewAttachmentScreen.formError =
              "Voer een link in.";
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoNewAttachmentScreen.formError = "";
              callback(this.state);
            }, 5000);
          } else {
            // save
            this.state.loading = true;
            this.attachmentVersionModelApi
              .apiGrexmanagerAttachmentVersionModelCreate({
                attachmentVersionModelCreateRequest: {
                  version: this.state.projectScreen.selectedVersionModel,
                  type: TypeEnum.Link,
                  categoryAttachment:
                    this.state.versionInfoNewAttachmentScreen
                      .categoryAttachment,
                  name: this.state.versionInfoNewAttachmentScreen.displayName,
                  urlLink: this.state.versionInfoNewAttachmentScreen.linkUrl,
                },
              })
              .then((_) => {
                this.state.loading = false;
                callback(this.state);
                // this.changeScreen(ScreenState.Projects, callback);

                if (this.state.projectScreen.selectedVersionModel) {
                  this.loadVersionModelInfoScreen(
                    this.state.projectScreen.selectedVersionModel,
                    callback
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                this.state.versionInfoNewAttachmentScreen.formError =
                  "Er ging iets mis tijdens het opslaan.";
                this.state.loading = false;
                callback(this.state);
                setTimeout(() => {
                  this.state.versionInfoNewAttachmentScreen.formError = "";
                  callback(this.state);
                }, 5000);
              });
          }
        } else if (
          this.state.versionInfoNewAttachmentScreen.attachmentType ===
          TypeEnum.File
        ) {
          if (this.state.versionInfoNewAttachmentScreen.file) {
            this.state.loading = false;
            callback(this.state);

            var formData = new FormData();
            formData.append(
              "version",
              `${this.state.projectScreen.selectedVersionModel}`
            );
            formData.append("type", TypeEnum.File);
            formData.append(
              "category_attachment",
              `${this.state.versionInfoNewAttachmentScreen.categoryAttachment}`
            );
            formData.append(
              "name",
              this.state.versionInfoNewAttachmentScreen.file.name
            );
            formData.append(
              "file",
              this.state.versionInfoNewAttachmentScreen.file
            );

            const url = `${this.basePath()}/api/grexmanager/attachment-version-model/`;
            const xhr = new XMLHttpRequest();

            xhr.onreadystatechange = (e) => {
              if (xhr.readyState === 4) {
                if (xhr.status === 201) {
                  this.state.loading = false;
                  callback(this.state);

                  if (this.state.projectScreen.selectedVersionModel) {
                    this.loadVersionModelInfoScreen(
                      this.state.projectScreen.selectedVersionModel,
                      callback
                    );
                  }
                } else {
                  this.state.versionInfoNewAttachmentScreen.formError =
                    "Upload niet geslaagd.";
                  this.state.loading = false;
                  callback(this.state);
                  setTimeout(() => {
                    this.state.versionInfoNewAttachmentScreen.formError = "";
                    callback(this.state);
                  }, 5000);
                }
              }
            };

            xhr.open("POST", url, true);
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${this.state.currentUser.accessToken || ""}`
            );
            xhr.send(formData);
          } else {
            this.state.versionInfoNewAttachmentScreen.formError =
              "Selecteer een bestand.";
            callback(this.state);
            setTimeout(() => {
              this.state.versionInfoNewAttachmentScreen.formError = "";
              callback(this.state);
            }, 5000);
          }
        }
      } else {
        this.state.versionInfoNewAttachmentScreen.formError =
          "Bijlagecategorie niet geselecteerd.";
        callback(this.state);
        setTimeout(() => {
          this.state.versionInfoNewAttachmentScreen.formError = "";
          callback(this.state);
        }, 5000);
      }
    }
  }
}
