import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import "./ClientMapScreen.css";
// import ButtonList from "@shared/components/ButtonList";
// import Section from "../../components/Section";
// import { TextInput, TextInputType } from "@shared/components/TextInput";
// import DropDown from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
// import {
//   IndexToRoleEnum,
//   RoleEnumToDropDown,
//   RoleEnumToIndex,
//   dropDownListPrintItem,
//   formatDate,
// } from "@shared/utils/helperFunctions";
// import { RoleEnum } from "@shared/client/lib";
// import Alert from "@shared/components/Alert";
import Alert from "@shared/components/Alert";
import Iframe from "react-iframe";

interface ClientMapScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ClientMapScreen extends React.Component<ClientMapScreenProps> {
  render() {
    return (
      <div id="ClientMapScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Klantgebied bewerken",
            },
          ]}
        ></BreadCrumbs>

        {(() => {
          if (this.props.state.ClientMapScreen.errorMessage !== "") {
            return (
              <Alert
                Error={true}
                Message={this.props.state.ClientMapScreen.errorMessage}
              ></Alert>
            );
          }
        })()}

        <div className="ClientMapScreen-MapViewerHolder">
          {(() => {
            if (this.props.state.ClientMapScreen.outlineUri) {
              return (
                <Iframe
                  url={this.props.state.ClientMapScreen.outlineUri}
                  width="100%"
                  height="100%"
                />
              );
            }
          })()}
        </div>
      </div>
    );
  }
}
