/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TemplateModel,
} from '../models';
import {
    TemplateModelFromJSON,
    TemplateModelToJSON,
} from '../models';

/**
 * 
 */
export class TemplateModelApi extends runtime.BaseAPI {

    /**
     * Endpoint for receiving a list of template models
     */
    async apiGrexmanagerTemplateModelListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/template-model/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateModelFromJSON));
    }

    /**
     * Endpoint for receiving a list of template models
     */
    async apiGrexmanagerTemplateModelList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateModel>> {
        const response = await this.apiGrexmanagerTemplateModelListRaw(initOverrides);
        return await response.value();
    }

}
