import BreadCrumbs from "@shared/components/BreadCrumbs";
import React from "react";
import "./SubregionMapScreen.css";
// import ButtonList from "@shared/components/ButtonList";
// import Section from "../../components/Section";
// import { TextInput, TextInputType } from "@shared/components/TextInput";
// import DropDown from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
// import {
//   IndexToRoleEnum,
//   RoleEnumToDropDown,
//   RoleEnumToIndex,
//   dropDownListPrintItem,
//   formatDate,
// } from "@shared/utils/helperFunctions";
// import { RoleEnum } from "@shared/client/lib";
// import Alert from "@shared/components/Alert";
import Iframe from "react-iframe";

interface SubregionMapScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class SubregionMapScreen extends React.Component<SubregionMapScreenProps> {
  render() {
    return (
      <div id="SubregionMapScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
            },
            {
              title: "Deelgebieden",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Configuration_Subregions,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Deelgebied bewerken",
            },
          ]}
        ></BreadCrumbs>

        <div className="SubregionMapScreen-MapViewerHolder">
          {(() => {
            if (this.props.state.SubregionMapScreen.outlineUri) {
              return (
                <Iframe
                  url={this.props.state.SubregionMapScreen.outlineUri}
                  width="100%"
                  height="100%"
                />
              );
            }
          })()}
        </div>
      </div>
    );
  }
}
