import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiPlus, FiSave } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./PhaseScreen.css";

interface PhaseScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class PhaseScreen extends React.Component<PhaseScreenProps> {
  render() {
    return (
      <div id="PhaseScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Configuratie",
            },
            {
              title: "Fasedefinities",
            },
          ]}
        ></BreadCrumbs>

        <Section>
          {(() => {
            if (this.props.state.phaseScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.phaseScreen.formError}
                ></Alert>
              );
            }
          })()}
          <div>
            <table className="PhaseScreen-Table">
              <tbody>
                <tr>
                  <th>Omschrijving</th>
                </tr>
                {this.props.state.phaseScreen.phasesList.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className="PhaseRowContainer">
                          <div className="PhaseDescBox">
                            <TextInput
                              type={TextInputType.Text}
                              placeholder={item.description}
                              value={item.newDescription}
                              onChange={(newvalue) => {
                                this.props.stateHandler.onPhaseEditInputChange(
                                  item.id,
                                  newvalue,
                                  this.props.updateStateCallback
                                );
                              }}
                            ></TextInput>
                          </div>
                          <div className="PhaseActionBox">
                            <div
                              className="PhaseScreen-ListItem"
                              onClick={() => {
                                this.props.stateHandler.onPhaseDeleteClick(
                                  item.id,
                                  this.props.updateStateCallback
                                );
                              }}
                            >
                              <AiOutlineDelete />
                            </div>
                          </div>

                          <div
                            className="PhaseActionBox"
                            style={{
                              display: item.saveButtonVisable
                                ? "block"
                                : "none",
                            }}
                          >
                            <div
                              className="PhaseScreen-ListItem"
                              onClick={() => {
                                this.props.stateHandler.onPhaseEditClick(
                                  item.id,
                                  item.newDescription,
                                  this.props.updateStateCallback
                                );
                              }}
                            >
                              <FiSave />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <div className="line"></div>
                <tr>
                  <th>Fasedefinitie toevoegen</th>
                </tr>
                <tr>
                  <td>
                    <div className="PhaseRowContainer">
                      <div className="PhaseDescBox">
                        <TextInput
                          value={this.props.state.phaseScreen.newPhase}
                          type={TextInputType.Text}
                          placeholder="Fasedefinitie"
                          onChange={(newvalue) => {
                            this.props.stateHandler.onNewPhaseInputChange(
                              newvalue,
                              this.props.updateStateCallback
                            );
                          }}
                        ></TextInput>
                      </div>

                      <div className="PhaseActionBox">
                        <div
                          className="PhaseScreen-ListItem"
                          onClick={() => {
                            this.props.stateHandler.onPhaseAddClick(
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          <FiPlus />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Section>
      </div>
    );
  }
}
