/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionModelUpdateRequest
 */
export interface VersionModelUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionModelUpdateRequest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionModelUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VersionModelUpdateRequest
     */
    phase: number | null;
}

/**
 * Check if a given object implements the VersionModelUpdateRequest interface.
 */
export function instanceOfVersionModelUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "phase" in value;

    return isInstance;
}

export function VersionModelUpdateRequestFromJSON(json: any): VersionModelUpdateRequest {
    return VersionModelUpdateRequestFromJSONTyped(json, false);
}

export function VersionModelUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'phase': json['phase'],
    };
}

export function VersionModelUpdateRequestToJSON(value?: VersionModelUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'phase': value.phase,
    };
}

