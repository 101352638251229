/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadSavedModelRequest
 */
export interface UploadSavedModelRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadSavedModelRequest
     */
    file: string;
}

/**
 * Check if a given object implements the UploadSavedModelRequest interface.
 */
export function instanceOfUploadSavedModelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "file" in value;

    return isInstance;
}

export function UploadSavedModelRequestFromJSON(json: any): UploadSavedModelRequest {
    return UploadSavedModelRequestFromJSONTyped(json, false);
}

export function UploadSavedModelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadSavedModelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function UploadSavedModelRequestToJSON(value?: UploadSavedModelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
    };
}

