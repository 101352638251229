import { ApiGrexmanagerDocumentationRetrieveRequest } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
export class DocumentationStateHandler {
  loadDocumentation(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Documentation;
    this.state.DocumentationScreen.documentationsManual = [];
    this.state.DocumentationScreen.documentationsInstructionalVideo = [];
    this.state.DocumentationScreen.documentationsReleaseNote = [];
    this.documentationApi
      .apiGrexmanagerDocumentationList()
      .then((documentations) => {
        documentations.map((documentation) => {
          if (documentation.documentationType === "manual") {
            this.state.DocumentationScreen.documentationsManual.push(
              documentation
            );
          } else if (
            documentation.documentationType === "instructional_video"
          ) {
            this.state.DocumentationScreen.documentationsInstructionalVideo.push(
              documentation
            );
          } else if (documentation.documentationType === "release_note") {
            this.state.DocumentationScreen.documentationsReleaseNote.push(
              documentation
            );
          }
          return documentation;
        });
        callback(this.state);
      })
      .catch((error) => {
        // Error while loading documentation
        console.log(error);
        this.state.DocumentationScreen.errorMessage =
          "Fout bij het laden van de documentatie.";
        callback(this.state);
        setTimeout(() => {
          this.state.DocumentationScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  OpenPdfFile(
    this: AppStateHandler,
    documentationId: number,
    callback: (newState: AppStateType) => void
  ) {
    const request: ApiGrexmanagerDocumentationRetrieveRequest = {
      id: documentationId,
    };
    this.documentationApi
      .apiGrexmanagerDocumentationRetrieve(request)
      .then((documentation: any) => {
        const pdfURL = URL.createObjectURL(documentation);
        window.open(pdfURL, "_blank");
        callback(this.state);
      })
      .catch((error) => {
        // Error while opening documentation
        console.log(error);
        this.state.DocumentationScreen.errorMessage =
          "Fout bij het openen van de documentatie.";
        callback(this.state);
        setTimeout(() => {
          this.state.DocumentationScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }
}
