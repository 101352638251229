import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class UserListStateHandler {
  loadUserList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.UserList;
    this.state.UserListScreen.userList = [];
    callback(this.state);
    this.userAdminApi
      .apiGrexmanagerUserList()
      .then((users) => {
        this.state.UserListScreen.userList = users.map((user) => {
          return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
            isActive: user.isActive,
            mfaVerified: user.mfaVerified,
            blocked: user.blocked,
            lastLogin: user.lastLogin,
          };
        });
        callback(this.state);
      })
      .catch((error) => {
        // Could not load user list
        console.log(error);
        this.state.UserListScreen.userlist_message =
          "Fout bij het laden van de lijst met gebruikers.";
        callback(this.state);
        setTimeout(() => {
          this.state.UserListScreen.userlist_message = "";
          callback(this.state);
        }, 5000);
      });
  }

  SelectUser(
    this: AppStateHandler,
    userId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.id = userId;
    this.changeScreen(ScreenState.EditUser, callback);
  }

  ForceMfaAllUsers(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.userAdminApi
      .apiGrexmanagerUserForceMfaCreate()
      .then(() => {
        this.state.UserListScreen.userlist_message =
          "Forceren van Multi-Factor Authenticatie (MFA) gelukt!";
        callback(this.state);
        setTimeout(() => {
          this.state.UserListScreen.userlist_message = "";
          callback(this.state);
        }, 5000);
      })
      .catch((error) => {
        // Could not force mfa for all users
        console.log(error);
        this.state.UserListScreen.userlist_message =
          "Fout bij forceren van Multi-Factor Authenticatie (MFA) voor alle gebruikers.";
        callback(this.state);
        setTimeout(() => {
          this.state.UserListScreen.userlist_message = "";
          callback(this.state);
        }, 5000);
      });
  }
}
