import { checkPasswordPolicy } from "src/helperFunctions";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
export class ResetPasswordNewPasswordStateHandler {
  ResetPasswordNewPasswordForm(
    this: AppStateHandler,
    password: {
      newPassword?: string;
      newPasswordRepeat?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (password.newPassword !== undefined) {
      this.state.ResetPassword.newPassword = password.newPassword;
      checkPasswordPolicy(
        this.state.PasswordPolicy.newPasswordRequirements,
        this.state.ResetPassword.newPassword,
        this.state.PasswordPolicy.notAppliedRequirements
      );
    }
    if (password.newPasswordRepeat !== undefined) {
      this.state.ResetPassword.newPasswordRepeat = password.newPasswordRepeat;
      this.ResetPasswordVlaidateNewPasswordRepeat(callback);
    }
    callback(this.state);
  }

  ResetPasswordVlaidateNewPasswordRepeat(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.ResetPassword.newPassword !==
      this.state.ResetPassword.newPasswordRepeat
    ) {
      this.state.ResetPassword.newPasswordError =
        "Wachtwoorden komen niet overeen";
      callback(this.state);
      return false;
    } else {
      this.state.ResetPassword.newPasswordError = "";
      callback(this.state);
      return true;
    }
  }

  ResetPasswordSetNewPassword(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.ResetPasswordVlaidateNewPasswordRepeat(callback)) {
      if (this.state.PasswordPolicy.notAppliedRequirements.length > 0) {
        this.state.ResetPassword.newPasswordError =
          "Wachtwoord voldoet niet aan de eisen";
        callback(this.state);
        return;
      }
      this.state.ResetPassword.newPasswordError = "";
      this.authApi
        .apiGrexmanagerUserResetPasswordSetPasswordCreate({
          resetPasswordSetPassword: {
            email: this.state.ResetPassword.email,
            resetPasswordToken: this.state.ResetPassword.resetPasswordCode,
            resetPassword2faToken: this.state.ResetPassword.mfatoken,
            newPassword: this.state.ResetPassword.newPassword,
          },
        })
        .then(() => {
          this.state.ResetPassword.newPasswordError = "";
          this.state.ResetPassword.newPasswordinfo = "Wachtwoord gewijzigd";
          callback(this.state);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.state.ResetPassword.newPasswordError =
              "Fout bij het wijzigen van uw wachtwoord.";
            callback(this.state);
          }
        });
    }
  }
}
