import { ProjectSortOption } from "../../GrexManagerFrontend/src/Types";
import { DropDownListItem } from "@shared/components/DropDown";

// Functions to convert ProjectSortOption enum to DropDownListItems
export const ProjectSortOptionToDropDown = (): DropDownListItem[] => {
  return (
    Object.keys(ProjectSortOption) as Array<keyof typeof ProjectSortOption>
  ).map((key, index) => {
    let displayName = "???";
    switch (ProjectSortOption[key]) {
      case ProjectSortOption.LastEdited:
        displayName = "Laatst bewerkt";
        break;
      case ProjectSortOption.Alphabetical:
        displayName = "Alfabetisch";
        break;
      case ProjectSortOption.ProjectNumber:
        displayName = "Projectnummer";
        break;
      default:
        break;
    }
    return {
      key: index,
      name: displayName,
      disabled: false,
    };
  });
};
export const ProjectSortOptionToIndex = (option: ProjectSortOption): number => {
  return Object.values(ProjectSortOption).indexOf(option);
};
export const IndexToProjectSortOption = (
  optionIndex: number
): ProjectSortOption => {
  const key = Object.keys(ProjectSortOption)[
    optionIndex
  ] as keyof typeof ProjectSortOption;
  return ProjectSortOption[key];
};

export const checkPasswordPolicy = (
  requirements: string[],
  password: string,
  notAppliedRequirements: string[]
): void => {
  for (const requirement of requirements) {
    if (!doesPasswordMeetRequirement(requirement, password)) {
      if (!notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.push(requirement);
      }
    } else {
      if (notAppliedRequirements.includes(requirement)) {
        notAppliedRequirements.splice(
          notAppliedRequirements.indexOf(requirement),
          1
        );
      }
    }
  }
};

export const doesPasswordMeetRequirement = (
  requirement: string,
  password: string
): boolean => {
  if (requirement === "Minimaal 12 karakters") {
    if (password.length < 12) {
      return false;
    }
  }
  if (requirement === "Minimaal één hoofdletter") {
    if (password.toLowerCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één kleine letter") {
    if (password.toUpperCase() === password) {
      return false;
    }
  }
  if (requirement === "Minimaal één cijfer") {
    if (!/\d/.test(password)) {
      return false;
    }
  }
  if (requirement === "Minimaal één speciaal teken") {
    if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)) {
      return false;
    }
  }
  return true;
};
