import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./LoadingScreen.css";

interface LoadingScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LoadingScreen extends React.Component<LoadingScreenProps> {
  render() {
    return (
      <div id="LoadingScreen-Container">
        <div id="LoadingScreen-Section">
          <GrexManagerLogo />
        </div>
      </div>
    );
  }
}
