import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class SubregionStateHandler {
  loadSubregionList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Configuration_Subregions;
    this.state.subregionScreen.subregionsList = [];
    this.state.subregionScreen.newSubregion = "";
    callback(this.state);
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.subregionScreen.subregionsList = subregions.map(
          (subregion) => {
            return {
              id: subregion.id,
              description: subregion.description || "",
              newDescription: subregion.description || "",
              saveButtonVisable: false,
              outlineUri: subregion.outlineUri,
            };
          }
        );
        callback(this.state);
      })
      .catch((error) => {
        // Error while loading subregions
        this.state.subregionScreen.formError =
          "Fout bij het laden van deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.subregionScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onSubregionDeleteClick(
    this: AppStateHandler,
    subregionId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.subregionApi
      .apiGrexmanagerSubregionDestroyRaw({ id: subregionId, force: false })
      .then(() => {
        this.loadSubregionList(callback);
      })
      .catch((error) => {
        if (error.response.status === 423) {
          this.state.subregionScreen.subregionToDelete = subregionId;
          this.state.popUpState = PopUpState.DeleteSubregionAlert;
          callback(this.state);
        } else {
          // Error while deleting subregions
          this.state.subregionScreen.formError =
            "Fout bij het verwijderen van deelgebied.";
          callback(this.state);
          setTimeout(() => {
            this.state.subregionScreen.formError = "";
            callback(this.state);
          }, 5000);
        }
      });
  }

  closeAlert(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);
  }

  permanantlyDeleteSubregion(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);

    if (this.state.subregionScreen.subregionToDelete) {
      this.subregionApi
        .apiGrexmanagerSubregionDestroyRaw({
          id: this.state.subregionScreen.subregionToDelete,
          force: true,
        })
        .then(() => {
          this.loadSubregionList(callback);
        })
        .catch((error) => {
          console.log(error);
          // Error while deleting subregions
          this.state.subregionScreen.formError =
            "Fout bij het verwijderen van deelgebied.";
          callback(this.state);
          setTimeout(() => {
            this.state.subregionScreen.formError = "";
            callback(this.state);
          }, 5000);
        });

      this.state.subregionScreen.subregionToDelete = null;
      callback(this.state);
    }
  }

  onSubregionEditInputChange(
    this: AppStateHandler,
    subregionId: number,
    newDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    const subregionIndex = this.state.subregionScreen.subregionsList.findIndex(
      (subregion) => {
        return subregion.id === subregionId;
      }
    );
    if (subregionIndex > -1) {
      this.state.subregionScreen.subregionsList[subregionIndex].newDescription =
        newDescription;
      if (newDescription === "") {
        this.state.subregionScreen.subregionsList[
          subregionIndex
        ].saveButtonVisable = false;
      } else {
        this.state.subregionScreen.subregionsList[
          subregionIndex
        ].saveButtonVisable = true;
      }
      callback(this.state);
    }
  }

  onSubregionEditClick(
    this: AppStateHandler,
    subregionId: number,
    subregionDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    this.subregionApi
      .apiGrexmanagerSubregionUpdate({
        id: subregionId,
        subregionUpdateRequest: {
          description: subregionDescription,
        },
      })
      .then(() => {
        // Hide save button
        const subregionIndex =
          this.state.subregionScreen.subregionsList.findIndex((subregion) => {
            return subregion.id === subregionId;
          });
        if (subregionIndex > -1) {
          this.state.subregionScreen.subregionsList[
            subregionIndex
          ].saveButtonVisable = false;
          callback(this.state);
        }

        this.loadSubregionList(callback);
      })
      .catch((error) => {
        // Message in the frontend
        let message = "Er is een fout opgetreden tijdens het opslaan.";
        if (error.response.status === 409) {
          message = "Er bestaat al een deelgebied met deze naam.";
        }
        this.state.subregionScreen.formError = message;
        callback(this.state);
        setTimeout(() => {
          this.state.subregionScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onNewSubregionInputChange(
    this: AppStateHandler,
    newSubregion: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.subregionScreen.newSubregion = newSubregion;
    callback(this.state);
  }

  onSubregionAddClick(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.subregionScreen.newSubregion !== "") {
      this.subregionApi
        .apiGrexmanagerSubregionCreate({
          subregionCreateRequest: {
            description: this.state.subregionScreen.newSubregion,
            accessList: [],
          },
        })
        .then(() => {
          callback(this.state);
          this.loadSubregionList(callback);
        })
        .catch((error) => {
          // Message in the frontend
          let message = "Er is een fout opgetreden tijdens het opslaan.";
          if (error.response.status === 409) {
            message = "Er bestaat al een deelgebied met deze naam.";
          }
          this.state.subregionScreen.formError = message;
          callback(this.state);
          setTimeout(() => {
            this.state.subregionScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  onEditMapClick(
    this: AppStateHandler,
    subregionOutlineUri: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.SubregionMapScreen.outlineUri = subregionOutlineUri;
    this.state.screenState = ScreenState.MapViewer_Subregion;
    callback(this.state);
  }
}
