/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GrantTypeEnum } from './GrantTypeEnum';
import {
    GrantTypeEnumFromJSON,
    GrantTypeEnumFromJSONTyped,
    GrantTypeEnumToJSON,
} from './GrantTypeEnum';

/**
 * 
 * @export
 * @interface OAuthAccessTokenRequest
 */
export interface OAuthAccessTokenRequest {
    /**
     * 
     * @type {GrantTypeEnum}
     * @memberof OAuthAccessTokenRequest
     */
    grantType: GrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenRequest
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenRequest
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccessTokenRequest
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the OAuthAccessTokenRequest interface.
 */
export function instanceOfOAuthAccessTokenRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grantType" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "redirectUri" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "clientSecret" in value;

    return isInstance;
}

export function OAuthAccessTokenRequestFromJSON(json: any): OAuthAccessTokenRequest {
    return OAuthAccessTokenRequestFromJSONTyped(json, false);
}

export function OAuthAccessTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OAuthAccessTokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantType': GrantTypeEnumFromJSON(json['grant_type']),
        'code': json['code'],
        'redirectUri': json['redirect_uri'],
        'clientId': json['client_id'],
        'clientSecret': json['client_secret'],
    };
}

export function OAuthAccessTokenRequestToJSON(value?: OAuthAccessTokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grant_type': GrantTypeEnumToJSON(value.grantType),
        'code': value.code,
        'redirect_uri': value.redirectUri,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}

