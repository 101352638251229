import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class TypeProjectStateHandler {
  loadTypeProjectList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Configuration_TypeProject;
    this.state.typeProjectScreen.typeProjectList = [];
    this.state.typeProjectScreen.newTypeProject = "";

    callback(this.state);
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.typeProjectScreen.typeProjectList = typeProject.map(
          (typeProject) => {
            return {
              id: typeProject.id,
              description: typeProject.description || "",
              newDescription: typeProject.description || "",
              saveButtonVisable: false,
            };
          }
        );
        callback(this.state);
      })
      .catch((error) => {
        // Error while loading type projects
        this.state.typeProjectScreen.formError =
          "Fout bij het laden van projecttypen.";
        callback(this.state);
        setTimeout(() => {
          this.state.typeProjectScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onTypeProjectDeleteClick(
    this: AppStateHandler,
    typeProjectId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.typeProjectApi
      .apiGrexmanagerTypeProjectDestroyRaw({
        id: typeProjectId,
        force: false,
      })
      .then(() => {
        this.loadTypeProjectList(callback);
      })
      .catch((error) => {
        if (error.response.status === 423) {
          this.state.typeProjectScreen.typeProjectToDelete = typeProjectId;
          this.state.popUpState = PopUpState.DeleteTypeProjectAlert;
          callback(this.state);
        } else {
          // Error while deleting type projects
          this.state.typeProjectScreen.formError =
            "Fout bij het verwijderen van projecttype.";
          callback(this.state);
          setTimeout(() => {
            this.state.typeProjectScreen.formError = "";
            callback(this.state);
          }, 5000);
        }
      });
  }

  closeDeleteTypeProjectAlert(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);
  }

  permanantlyDeleteTypeProject(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.popUpState = PopUpState.Hidden;
    callback(this.state);

    if (this.state.typeProjectScreen.typeProjectToDelete) {
      this.typeProjectApi
        .apiGrexmanagerTypeProjectDestroyRaw({
          id: this.state.typeProjectScreen.typeProjectToDelete,
          force: true,
        })
        .then(() => {
          this.loadTypeProjectList(callback);
        })
        .catch((error) => {
          console.log(error);
          // Error while deleting type projects
          this.state.typeProjectScreen.formError =
            "Fout bij het verwijderen van projecttype.";
          callback(this.state);
          setTimeout(() => {
            this.state.typeProjectScreen.formError = "";
            callback(this.state);
          }, 5000);
        });

      this.state.typeProjectScreen.typeProjectToDelete = null;
      callback(this.state);
    }
  }

  onTypeProjectEditInputChange(
    this: AppStateHandler,
    typeProjectId: number,
    newDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    const typeProjectIndex =
      this.state.typeProjectScreen.typeProjectList.findIndex((typeProject) => {
        return typeProject.id === typeProjectId;
      });
    if (typeProjectIndex > -1) {
      this.state.typeProjectScreen.typeProjectList[
        typeProjectIndex
      ].newDescription = newDescription;
      if (newDescription === "") {
        this.state.typeProjectScreen.typeProjectList[
          typeProjectIndex
        ].saveButtonVisable = false;
      } else {
        this.state.typeProjectScreen.typeProjectList[
          typeProjectIndex
        ].saveButtonVisable = true;
      }
      callback(this.state);
    }
  }

  onTypeProjectEditClick(
    this: AppStateHandler,
    typeProjectId: number,
    typeProjectDescription: string,
    callback: (newState: AppStateType) => void
  ) {
    this.typeProjectApi
      .apiGrexmanagerTypeProjectUpdate({
        id: typeProjectId,
        typeProject: {
          id: typeProjectId,
          description: typeProjectDescription,
        },
      })
      .then(() => {
        // Hide save button
        const typeProjectIndex =
          this.state.typeProjectScreen.typeProjectList.findIndex(
            (typeProject) => {
              return typeProject.id === typeProjectId;
            }
          );
        if (typeProjectIndex > -1) {
          this.state.typeProjectScreen.typeProjectList[
            typeProjectIndex
          ].saveButtonVisable = false;
          callback(this.state);
        }

        this.loadTypeProjectList(callback);
      })
      .catch((error) => {
        // Message in the frontend
        let message = "Er is een fout opgetreden tijdens het opslaan.";
        if (error.response.status === 409) {
          message = "Er bestaat al een projecttype met deze naam.";
        }
        this.state.typeProjectScreen.formError = message;
        callback(this.state);
        setTimeout(() => {
          this.state.typeProjectScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onNewTypeProjectInputChange(
    this: AppStateHandler,
    newTypeProject: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.typeProjectScreen.newTypeProject = newTypeProject;
    callback(this.state);
  }

  onTypeProjectAddClick(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.typeProjectScreen.newTypeProject !== "") {
      this.typeProjectApi
        .apiGrexmanagerTypeProjectCreate({
          typeProject: {
            id: 0,
            description: this.state.typeProjectScreen.newTypeProject,
          },
        })
        .then(() => {
          callback(this.state);
          this.loadTypeProjectList(callback);
        })
        .catch((error) => {
          // Message in the frontend
          let message = "Er is een fout opgetreden tijdens het opslaan.";
          if (error.response.status === 409) {
            message = "Er bestaat al een projecttype met deze naam.";
          }
          this.state.typeProjectScreen.formError = message;
          callback(this.state);
          setTimeout(() => {
            this.state.typeProjectScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
