import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class OverviewStateHandler {
  initOverviewMapScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.MapViewer_Overview;
    callback(this.state);

    this.mapViewerApi
      .apiGrexmanagerMapviewerOverviewRetrieve()
      .then((response) => {
        this.state.OverviewMapScreen.viewerUri = response.viewerUri;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);
        this.state.OverviewMapScreen.errorMessage =
          "Fout bij het laden van de overzichtskaart.";
        callback(this.state);
        setTimeout(() => {
          this.state.OverviewMapScreen.errorMessage = "";
          callback(this.state);
        }, 5000);
      });
  }

  closeOverviewMapScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.OverviewMapScreen.viewerUri = null;
    callback(this.state);
  }
}
