import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  IndexToRoleEnum,
  RoleEnumToIndex,
  RoleProjectEnumToDropDown,
} from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./NewProjectScreen.css";

interface NewProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewProjectScreen extends React.Component<NewProjectScreenProps> {
  render() {
    return (
      <div id="NewProjectScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Nieuw project",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={this.props.state.newProjectScreen.loadingForm}>
          {(() => {
            if (this.props.state.newProjectScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.newProjectScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="NewProjectScreen-Table">
            <tbody>
              <tr>
                <td>Projectnaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Projectnaam"
                    onChange={(newValue) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { projectName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newProjectScreen.projectName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newValue) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { description: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newProjectScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Projectnummer</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Projectnummer"
                    onChange={(newValue) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { projectNumber: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newProjectScreen.projectNumber}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Projecttype*</td>
                <td>
                  <DropDown
                    list={this.props.state.newProjectScreen.typeProjectList}
                    onChange={(newKey) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { typeProject: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={this.props.state.newProjectScreen.typeProject}
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <td>Deelgebied*</td>
                <td>
                  <DropDown
                    list={this.props.state.newProjectScreen.subregions}
                    onChange={(newKey) => {
                      this.props.stateHandler.newProjectSubregionChanged(
                        newKey,
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={this.props.state.newProjectScreen.subregion}
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <td>Gemeente / organisatie</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Gemeente / organisatie"
                    onChange={(newValue) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { organisation: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newProjectScreen.organisation}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Projectmanager</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Projectmanager"
                    onChange={(newValue) => {
                      this.props.stateHandler.newProjectUpdateForm(
                        { projectManager: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newProjectScreen.projectManager}
                  ></TextInput>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section disabled={this.props.state.newProjectScreen.loadingRoles}>
          <table className="NewProjectScreen-Table">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Rechten</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (this.props.state.newProjectScreen.userRoles.length === 0) {
                  return (
                    <tr>
                      <td>Aan het laden...</td>
                      <td className="NewProjectScreen-Placeholder"></td>
                    </tr>
                  );
                }
              })()}
              {this.props.state.newProjectScreen.userRoles.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.nameFull}</td>
                    <td>
                      <DropDown
                        list={RoleProjectEnumToDropDown()}
                        onChange={(newKey) => {
                          this.props.stateHandler.newProjectChangeRole(
                            item.id,
                            IndexToRoleEnum(newKey),
                            this.props.updateStateCallback
                          );
                        }}
                        selectedKey={RoleEnumToIndex(item.role)}
                      ></DropDown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.newProjectSave(
                  this.props.updateStateCallback
                );
              },
              disabled:
                this.props.state.newProjectScreen.loadingForm ||
                this.props.state.newProjectScreen.loadingRoles,
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
