import ButtonList from "@shared/components/ButtonList";
import React from "react";
import "./MessagePopup.css";

interface MessagePopupProps {
  message: string;
  massageTitle: string;
  okayCallback: () => void;
  cancelCallback: () => void;
  okayButtonText?: string;
  cancelButtonText?: string;
}

export default class MessagePopup extends React.Component<MessagePopupProps> {
  render() {
    const okayButtonText: string = this.props.okayButtonText
      ? this.props.okayButtonText
      : "Verwijderen";
    const cancelButtonText: string = this.props.cancelButtonText
      ? this.props.cancelButtonText
      : "Annuleren";

    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>
          <div id="MessagePopup-Text">{this.props.message}</div>
          <div id="MessagePopup-Buttons">
            <ButtonList
              data={[
                {
                  title: okayButtonText,
                  callback: () => {
                    this.props.okayCallback();
                  },
                  warning: true,
                },
                {
                  title: cancelButtonText,
                  callback: () => {
                    this.props.cancelCallback();
                  },
                },
              ]}
            ></ButtonList>
          </div>
        </div>
      </div>
    );
  }
}
