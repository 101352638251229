import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import { formatDate } from "@shared/utils/helperFunctions";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import "./LogScreen.css";

interface LogScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class LogScreen extends React.Component<LogScreenProps> {
  render() {
    return (
      <div id="LogScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Logs",
            },
          ]}
        ></BreadCrumbs>

        <div id="LogScreen-ScrollView">
          <div id="LogScreen-ScrollHolder">
            <table id="LogScreen-Table">
              <thead>
                <tr>
                  <th>Datum + Tijd</th>
                  <th>Gebruiker</th>
                  <th>Actie</th>
                  <th>IP-adres</th>
                </tr>
              </thead>
              <tbody>
                {this.props.state.logScreen.logs.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{formatDate(item.timestamp)}</td>
                      <td>{item.userNameFull}</td>
                      <td>{item.description}</td>
                      <td>{item.ipaddress}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {(() => {
              if (this.props.state.logScreen.errorMessage !== "") {
                return (
                  <Alert
                    Error={true}
                    Message={this.props.state.logScreen.errorMessage}
                  ></Alert>
                );
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}
