import GrexInfo from "@shared/GrexInfo";
import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import SectionTitle from "@shared/components/SectionTitle";
import React from "react";
import { FiFile, FiVideo } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import Section from "../../components/Section";
import YoutubePopup from "../../components/YoutubePopup";
import "./DocumentationScreen.css";

interface DocumentationScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class DocumentationScreen extends React.Component<DocumentationScreenProps> {
  render() {
    return (
      <div id="DocumentationScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Documentatie",
            },
          ]}
        ></BreadCrumbs>
        <Section>
          {(() => {
            if (this.props.state.DocumentationScreen.errorMessage !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.DocumentationScreen.errorMessage}
                ></Alert>
              );
            }
          })()}
          <SectionTitle subtitle={true}>Handleidingen</SectionTitle>
          <table className="DocumentationScreen-Table">
            <tbody>
              {this.props.state.DocumentationScreen.documentationsManual.map(
                (item) => {
                  return (
                    <tr key={item.id}>
                      <td className="Icon">
                        <FiFile />
                      </td>
                      <td>
                        <p
                          className="DocumentationLink"
                          onClick={() => {
                            this.props.stateHandler.OpenPdfFile(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          {item.name}
                        </p>
                        <p className="DocumentationDescription">
                          {item.description}
                        </p>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle subtitle={true}>Instructievideo's</SectionTitle>
          <table className="DocumentationScreen-Table">
            <tbody>
              {this.props.state.DocumentationScreen.documentationsInstructionalVideo.map(
                (item) => {
                  return (
                    <tr key={item.id}>
                      <td className="Icon">
                        <FiVideo />
                      </td>
                      <td>
                        <YoutubePopup
                          videoUrl={item.videoUrl || ""}
                          docName={item.name}
                        />
                        <p className="DocumentationDescription">
                          {item.description}
                        </p>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionTitle subtitle={true}>Release notes</SectionTitle>
          <table className="DocumentationScreen-Table">
            <tbody>
              {this.props.state.DocumentationScreen.documentationsReleaseNote.map(
                (item) => {
                  return (
                    <tr key={item.id}>
                      <td className="Icon">
                        <FiFile />
                      </td>
                      <td>
                        <p
                          className="DocumentationLink"
                          onClick={() => {
                            this.props.stateHandler.OpenPdfFile(
                              item.id,
                              this.props.updateStateCallback
                            );
                          }}
                        >
                          {item.name}
                        </p>
                        <p className="DocumentationDescription">
                          {item.description}
                        </p>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Section>
        <div id="DocumentationScreen-GrexVersion">
          GrexManager {GrexInfo.version}
        </div>
      </div>
    );
  }
}
