/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';

/**
 * 
 * @export
 * @interface VersionModelInfoAttachmentResponse
 */
export interface VersionModelInfoAttachmentResponse {
    /**
     * 
     * @type {number}
     * @memberof VersionModelInfoAttachmentResponse
     */
    readonly id: number;
    /**
     * 
     * @type {TypeEnum}
     * @memberof VersionModelInfoAttachmentResponse
     */
    type: TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoAttachmentResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoAttachmentResponse
     */
    readonly categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelInfoAttachmentResponse
     */
    urlLink?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VersionModelInfoAttachmentResponse
     */
    readonly timestampUpload: Date;
}

/**
 * Check if a given object implements the VersionModelInfoAttachmentResponse interface.
 */
export function instanceOfVersionModelInfoAttachmentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "timestampUpload" in value;

    return isInstance;
}

export function VersionModelInfoAttachmentResponseFromJSON(json: any): VersionModelInfoAttachmentResponse {
    return VersionModelInfoAttachmentResponseFromJSONTyped(json, false);
}

export function VersionModelInfoAttachmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelInfoAttachmentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': TypeEnumFromJSON(json['type']),
        'name': json['name'],
        'categoryName': json['category_name'],
        'urlLink': !exists(json, 'url_link') ? undefined : json['url_link'],
        'timestampUpload': (new Date(json['timestamp_upload'])),
    };
}

export function VersionModelInfoAttachmentResponseToJSON(value?: VersionModelInfoAttachmentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TypeEnumToJSON(value.type),
        'name': value.name,
        'url_link': value.urlLink,
    };
}

