import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import {
  convertToDutchDateTime,
  extendUserRole,
} from "@shared/utils/helperFunctions";
import React from "react";
import { FiSlash } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./UserListScreen.css";

interface UserListScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class UserListScreen extends React.Component<UserListScreenProps> {
  render() {
    return (
      <div id="UserListScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruikers",
            },
          ]}
        ></BreadCrumbs>
        {(() => {
          if (this.props.state.UserListScreen.userlist_message !== "") {
            return (
              <Alert
                Error={false}
                Message={this.props.state.UserListScreen.userlist_message}
              ></Alert>
            );
          }
        })()}
        <Section>
          <table className="UserListScreen-Table">
            <tbody>
              <tr>
                <th>Naam</th>
                <th>Rol</th>
                <th>Laatst online</th>
                <th>Geblokkeerd</th>
                <th>Multi-Factor Authenticatie (MFA)</th>
              </tr>
              {this.props.state.UserListScreen.userList.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className="UserListScreen-ListItem"
                    onClick={() =>
                      this.props.stateHandler.SelectUser(
                        item.id,
                        this.props.updateStateCallback
                      )
                    }
                  >
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>{extendUserRole(item.role)}</td>

                    <td>
                      {convertToDutchDateTime(item.lastLogin?.toString())}
                    </td>
                    <td>{item.blocked ? <FiSlash /> : "Niet geblokkeerd"}</td>
                    <td>
                      {item.mfaVerified ? "Ingeschakeld" : "Uitgeschakeld"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Section>
        <ButtonList
          data={[
            {
              title: "Gebruiker toevoegen",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.CreateUser,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Forceer MFA voor alle gebruikers",
              callback: () => {
                this.props.stateHandler.ForceMfaAllUsers(
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
