import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";

export class ResetPasswordEmailStateHandler {
  ResetPasswordSendEmail(
    this: AppStateHandler,
    email: string,
    callback: (newState: AppStateType) => void
  ) {
    const emailPattern: RegExp =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailPattern.test(email)) {
      this.state.ResetPassword.resetpasswordMessage = "Ongeldig e-mailadres";
      callback(this.state);
      return;
    } else {
      this.state.ResetPassword.resetpasswordMessage = "";
      callback(this.state);

      this.authApi
        .apiGrexmanagerUserResetPasswordSendTokenCreate({
          resetPasswordSendMail: {
            email: email,
          },
        })
        .then(() => {
          this.changeLoginScreen(LoginState.ResetPasswordCode, callback);
        })
        .catch((error) => {
          console.log(error);
          this.state.ResetPassword.resetpasswordMessage =
            "Fout bij het verzenden van de e-mail met de wachtwoord reset link";
          callback(this.state);
          setTimeout(() => {
            this.state.ResetPassword.resetpasswordMessage = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  ResetPasswordSendEmailForm(
    this: AppStateHandler,
    email: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.ResetPassword.resetpasswordMessage = "";
    this.state.ResetPassword.email = email;
    callback(this.state);
  }
}
