import { RoleEnum } from "@shared/client/lib";
import { initialAppStateString } from "src/InitialAppState";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState, ScreenState } from "../../Types";
export class LoginStateHandler {
  login(
    this: AppStateHandler,
    email: string,
    password: string,
    callback: (newState: AppStateType) => void
  ) {
    this.authApi
      .apiGrexmanagerUserLoginCreate({
        login: {
          email: email,
          password: password,
        },
      })
      .then((response) => {
        if (response.access !== null) {
          this.setAccessToken(response.access, callback);

          // Get the role
          this.userApi
            .apiGrexmanagerUserMeRetrieve()
            .then((response) => {
              this.state.currentUser.isSuperAdmin = response.isStaff || false;
              this.state.currentUser.fullName =
                response.firstName + " " + response.lastName || "";
              this.state.currentUser.userEmail = response.email || "";
              this.state.currentUser.mfaVerified =
                response.mfaVerified || false;
              this.state.currentUser.mfaForced = response.mfaForced || false;
              this.state.currentUser.userRole = response.role || RoleEnum.Non;
              this.state.loggedIn = true;
              this.changeScreen(ScreenState.Projects, callback);
            })
            .catch((error) => {
              console.log(error);
              this.state = JSON.parse(initialAppStateString); //sign out
              callback(this.state);
              this.initApp(callback);
            });
        } else if (response.mfa === true) {
          this.state.login2fa.useremail = email;
          this.state.loggedIn = false;
          this.state.mfaRequired = true;
          this.changeLoginScreen(LoginState.LoginMFA, callback);
          callback(this.state);
        }
      })
      .catch((error) => {
        // stay signed out
        if (error.response?.status === 405) {
          // user is blocked
          this.state.LoginScreen.login_error =
            "U kunt met dit account geen toegang krijgen tot GrexManager. Neem contact op met uw administrator.";
          console.log(error);
        } else {
          this.state.LoginScreen.login_error =
            "De combinatie van e-mailadres en wachtwoord is onjuist";
          console.log(error);
        }
        callback(this.state);
      });
  }
}
