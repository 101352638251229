import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class CategoryAttachmentStateHandler {
  loadCategoryAttachmentList(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Configuration_FileCategories;
    this.state.categoryAttachmentScreen.categoryAttachmentsList = [];
    this.state.categoryAttachmentScreen.newCategory = "";
    callback(this.state);
    this.categoryAttachmentApi
      .apiGrexmanagerCategoryAttachmentList()
      .then((categoryAttachments) => {
        this.state.categoryAttachmentScreen.categoryAttachmentsList =
          categoryAttachments.map((categoryAttachment) => {
            return {
              id: categoryAttachment.id,
              name: categoryAttachment.name || "",
              newName: categoryAttachment.name || "",
              saveButtonVisable: false,
            };
          });
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.state.categoryAttachmentScreen.formError =
          "Fout bij het laden van bestandscategorieën";
        callback(this.state);
        setTimeout(() => {
          this.state.categoryAttachmentScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onCategoryAttachmentDeleteClick(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void,
    categoryAttachmentId?: number,
    force = false
  ) {
    if (categoryAttachmentId === undefined) {
    } else {
      this.state.categoryAttachmentScreen.categoryAttachmentToDelete =
        categoryAttachmentId;
    }
    this.categoryAttachmentApi
      .apiGrexmanagerCategoryAttachmentDestroyRaw({
        id: this.state.categoryAttachmentScreen
          .categoryAttachmentToDelete as number,
        force,
      })
      .then(() => {
        this.loadCategoryAttachmentList(callback);
      })
      .catch((error) => {
        if (error.response.status === 423) {
          this.state.popUpState = PopUpState.DeleteCategoryAttachmentAlert;
          callback(this.state);
        } else {
          console.log(error);
          this.state.categoryAttachmentScreen.formError =
            "Fout bij het verwijderen van bestandscategorie";
          callback(this.state);
          setTimeout(() => {
            this.state.categoryAttachmentScreen.formError = "";
            callback(this.state);
          }, 5000);
        }
      });
  }

  onCategoryAttachmentEditInputChange(
    this: AppStateHandler,
    categoryAttachmentId: number,
    newName: string,
    callback: (newState: AppStateType) => void
  ) {
    const categoryAttachmentIndex =
      this.state.categoryAttachmentScreen.categoryAttachmentsList.findIndex(
        (categoryAttachment) => {
          return categoryAttachment.id === categoryAttachmentId;
        }
      );
    if (categoryAttachmentIndex > -1) {
      this.state.categoryAttachmentScreen.categoryAttachmentsList[
        categoryAttachmentIndex
      ].newName = newName;
      if (newName === "") {
        this.state.categoryAttachmentScreen.categoryAttachmentsList[
          categoryAttachmentIndex
        ].saveButtonVisable = false;
      } else {
        this.state.categoryAttachmentScreen.categoryAttachmentsList[
          categoryAttachmentIndex
        ].saveButtonVisable = true;
      }
      callback(this.state);
    }
  }

  onCategoryAttachmentEditClick(
    this: AppStateHandler,
    categoryAttachmentId: number,
    categoryAttachmentName: string,
    callback: (newState: AppStateType) => void
  ) {
    this.categoryAttachmentApi
      .apiGrexmanagerCategoryAttachmentUpdate({
        id: categoryAttachmentId,
        categoryAttachment: {
          name: categoryAttachmentName,
        },
      })
      .then(() => {
        // Hide save button
        const categoryAttachmentIndex =
          this.state.categoryAttachmentScreen.categoryAttachmentsList.findIndex(
            (categoryAttachment) => {
              return categoryAttachment.id === categoryAttachmentId;
            }
          );
        if (categoryAttachmentIndex > -1) {
          this.state.categoryAttachmentScreen.categoryAttachmentsList[
            categoryAttachmentIndex
          ].saveButtonVisable = false;
          callback(this.state);
        }

        this.loadCategoryAttachmentList(callback);
      })
      .catch((error) => {
        // Message in the frontend
        let message = "Er is een fout opgetreden tijdens het opslaan.";
        if (error.response.status === 409) {
          message = "Er bestaat al een bijlagecategorie met deze naam.";
        }
        this.state.categoryAttachmentScreen.formError = message;
        callback(this.state);
        setTimeout(() => {
          this.state.categoryAttachmentScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  onNewCategoryAttachmentInputChange(
    this: AppStateHandler,
    newCategoryAttachment: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.categoryAttachmentScreen.newCategory = newCategoryAttachment;
    callback(this.state);
  }

  onCategoryAttachmentAddClick(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.state.categoryAttachmentScreen.newCategory !== "") {
      this.categoryAttachmentApi
        .apiGrexmanagerCategoryAttachmentCreate({
          categoryAttachment: {
            name: this.state.categoryAttachmentScreen.newCategory,
          },
        })
        .then(() => {
          callback(this.state);
          this.loadCategoryAttachmentList(callback);
        })
        .catch((error) => {
          // Message in the frontend
          let message = "Er is een fout opgetreden tijdens het opslaan.";
          if (error.response.status === 409) {
            message = "Er bestaat al een bijlagecategorie met deze naam.";
          }
          this.state.categoryAttachmentScreen.formError = message;
          callback(this.state);
          setTimeout(() => {
            this.state.categoryAttachmentScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
