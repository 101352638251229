import {
  ApiGrexmanagerUserBlkCreateRequest,
  ApiGrexmanagerUserDestroyRequest,
  ApiGrexmanagerUserProjectRoleListRequest,
  ApiGrexmanagerUserProjectRoleUpdateRequest,
  ApiGrexmanagerUserResetMfaCreateRequest,
  ApiGrexmanagerUserRetrieveRequest,
  ApiGrexmanagerUserSubregionListRequest,
  ApiGrexmanagerUserSubregionUpdateRequest,
  ApiGrexmanagerUserTemplateReportListRequest,
  ApiGrexmanagerUserTemplateReportUpdateRequest,
  ApiGrexmanagerUserUpdateRequest,
  GrexUserBlocker,
  RoleEnum,
} from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

import { isEmailValid } from "@shared/utils/helperFunctions";

export class EditUserStateHandler {
  loadUser(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    this.state.screenState = ScreenState.EditUser;
    this.state.EditUser.allProjectRolesDropdown = null;
    callback(this.state);

    const Request: ApiGrexmanagerUserRetrieveRequest = {
      id: this.state.EditUser.id,
    };
    this.userAdminApi
      .apiGrexmanagerUserRetrieve(Request)
      .then((user) => {
        this.state.EditUser.firstName =
          user.firstName || this.state.EditUser.firstName;
        this.state.EditUser.lastName = user.lastName || "";
        this.state.EditUser.email = user.email || "";
        this.state.EditUser.role = user.role || RoleEnum.Non;
        this.state.EditUser.mfaVerified = user.mfaVerified || false;
        this.state.EditUser.mfaForced = user.mfaForced || false;
        this.state.EditUser.blocked = user.blocked || false;
        this.state.EditUser.lastLogin = user.lastLogin || null;
        callback(this.state);
        this.loadUserSubregions(this.state.EditUser.id, callback);
        this.loadUserTemplateReports(this.state.EditUser.id, callback);
        this.loadUserProjectsRoles(this.state.EditUser.id, callback);
      })
      .catch((error) => {
        // Could not load the user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het ophalen van de gebruiker.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  EditUserForm(
    this: AppStateHandler,
    editUserForm: {
      firstName?: string;
      lastName?: string;
      email?: string;
      role?: RoleEnum;
      mfaForced?: boolean;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (editUserForm.firstName !== undefined) {
      this.state.EditUser.firstName = editUserForm.firstName;
      callback(this.state);
    }
    if (editUserForm.lastName !== undefined) {
      this.state.EditUser.lastName = editUserForm.lastName;
      callback(this.state);
    }
    if (editUserForm.email !== undefined) {
      this.state.EditUser.email = editUserForm.email;
      callback(this.state);
    }
    if (editUserForm.role !== undefined) {
      this.state.EditUser.role = editUserForm.role;
      callback(this.state);
    }
  }

  EditUserForceMfa(
    this: AppStateHandler,
    mfaForced: boolean,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.mfaForced = mfaForced;
    callback(this.state);
  }

  validateEditUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.EditUserError = "";
    if (
      this.state.EditUser.firstName === "" ||
      this.state.EditUser.lastName === "" ||
      this.state.EditUser.email === ""
    ) {
      this.state.EditUser.EditUserError =
        "Niet alle vereiste velden zijn ingevuld.";
      callback(this.state);
      return false;
    } else if (!isEmailValid(this.state.EditUser.email)) {
      this.state.EditUser.EditUserError = "E-mailadres is niet geldig";
      callback(this.state);
      return false;
    } else {
      this.state.EditUser.EditUserError = "";
      callback(this.state);
      return true;
    }
  }

  EditUser(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    if (this.validateEditUser(callback)) {
      const Request: ApiGrexmanagerUserUpdateRequest = {
        id: this.state.EditUser.id,
        grexUserUpdate: {
          firstName: this.state.EditUser.firstName,
          lastName: this.state.EditUser.lastName,
          email: this.state.EditUser.email,
          role: this.state.EditUser.role,
          mfaForced: this.state.EditUser.mfaForced,
        },
      };

      this.userAdminApi
        .apiGrexmanagerUserUpdate(Request)
        .then(() => {
          this.changeScreen(ScreenState.UserList, callback);
          this.state.UserListScreen.userlist_message = "Opslaan gelukt!";
          callback(this.state);
          setTimeout(() => {
            this.state.UserListScreen.userlist_message = "";
            callback(this.state);
          }, 5000);
        })
        .catch((error) => {
          // Could not update the user
          console.log(error);
          this.state.EditUser.EditUserError =
            "Fout bij het updaten van de gebruiker.";
          callback(this.state);
          setTimeout(() => {
            this.state.EditUser.EditUserError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }

  BlockUser(this: AppStateHandler, callback: (newState: AppStateType) => void) {
    const Request: ApiGrexmanagerUserBlkCreateRequest = {
      id: this.state.EditUser.id,
    };

    this.userAdminApi
      .apiGrexmanagerUserBlkCreate(Request)
      .then((response: GrexUserBlocker) => {
        this.state.EditUser.blocked = response.blocked;
        callback(this.state);
      })
      .catch((error) => {
        // Could not block the user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het blokkeren van de gebruiker.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  DeleteUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserDestroyRequest = {
      id: this.state.EditUser.id,
    };
    this.userAdminApi
      .apiGrexmanagerUserDestroy(Request)
      .then(() => {
        this.changeScreen(ScreenState.UserList, callback);
        callback(this.state);
      })
      .catch((error) => {
        // Could not delete the user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het verwijderen van de gebruiker.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  loadUserSubregions(
    this: AppStateHandler,
    user_id: number,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserSubregionListRequest = {
      id: user_id,
    };
    this.userAdminApi
      .apiGrexmanagerUserSubregionList(Request)
      .then((subregions) => {
        this.state.EditUser.subregions = subregions;
        callback(this.state);
      })
      .catch((error) => {
        // Could not load subregions for this user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het laden van de deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  loadUserTemplateReports(
    this: AppStateHandler,
    user_id: number,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserTemplateReportListRequest = {
      id: user_id,
    };

    this.userAdminApi
      .apiGrexmanagerUserTemplateReportList(Request)
      .then((templateReports) => {
        this.state.EditUser.templateReports = templateReports;
        callback(this.state);
      })
      .catch((error) => {
        // Could not load template reports for this user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het laden van de rapportage sjablonen.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  loadUserProjectsRoles(
    this: AppStateHandler,
    user_id: number,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserProjectRoleListRequest = {
      id: user_id,
    };
    this.userAdminApi
      .apiGrexmanagerUserProjectRoleList(Request)
      .then((projectsRoles) => {
        this.state.EditUser.projectsRoles = projectsRoles;
        callback(this.state);
      })
      .catch((error) => {
        // Could not load projects for this user
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het laden van projectrechten.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  EditSubregionsAccess(
    this: AppStateHandler,
    subregion_id: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.subregions.forEach((subregion) => {
      if (subregion.subregionId === subregion_id) {
        subregion.access = !subregion.access;
        callback(this.state);
      }
    });
  }

  EditTemplateReportsAccess(
    this: AppStateHandler,
    templateReport_id: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.templateReports.forEach((templateReport) => {
      if (templateReport.templateReportId === templateReport_id) {
        templateReport.access = !templateReport.access;
        callback(this.state);
      }
    });
  }

  EditProjectsRoles(
    this: AppStateHandler,
    project_id: number,
    role: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.projectsRoles.forEach((projectRole) => {
      if (projectRole.projectId === project_id) {
        projectRole.role = role;
        this.state.EditUser.allProjectRolesDropdown = null;
        callback(this.state);
      }
    });
  }

  EditAllProjectRoles(
    this: AppStateHandler,
    role: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    this.state.EditUser.allProjectRolesDropdown = role;
    this.state.EditUser.projectsRoles.forEach((projectRole) => {
      projectRole.role = role;
    });
    callback(this.state);
  }

  saveUserSubregions(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserSubregionUpdateRequest = {
      id: this.state.EditUser.id,
      grexUserSubregionListResponse: this.state.EditUser.subregions,
    };

    this.userAdminApi
      .apiGrexmanagerUserSubregionUpdate(Request)
      .then(() => {
        this.state.EditUser.subregions_message = "Opslaan gelukt!";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.subregions_message = "";
          callback(this.state);
        }, 2000);
      })
      .catch((error) => {
        // Could not save subregions
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij opslaan van de deelgebieden.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  saveUserTemplateReports(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserTemplateReportUpdateRequest = {
      id: this.state.EditUser.id,
      grexUserTemplateReportListResponse: this.state.EditUser.templateReports,
    };

    this.userAdminApi
      .apiGrexmanagerUserTemplateReportUpdate(Request)
      .then(() => {
        this.state.EditUser.templateReports_message = "Opslaan gelukt!";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.templateReports_message = "";
          callback(this.state);
        }, 2000);
      })
      .catch((error) => {
        // Could not save template reports
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij opslaan van de rapportage sjablonen.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  saveUserProjectsRoles(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserProjectRoleUpdateRequest = {
      id: this.state.EditUser.id,
      grexUserProjectRoleListResponse: this.state.EditUser.projectsRoles,
    };

    this.userAdminApi
      .apiGrexmanagerUserProjectRoleUpdate(Request)
      .then(() => {
        this.state.EditUser.projectsRoles_message = "Opslaan gelukt!";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.projectsRoles_message = "";
          callback(this.state);
        }, 2000);
      })
      .catch((error) => {
        // Could not save project roles
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij opslaan van projectrechten.";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }

  ResetUserMFA(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    const Request: ApiGrexmanagerUserResetMfaCreateRequest = {
      id: this.state.EditUser.id,
    };

    this.userAdminApi
      .apiGrexmanagerUserResetMfaCreate(Request)
      .then(() => {
        this.loadUser(callback);
      })
      .catch((error) => {
        // Could not reset MFA
        console.log(error);
        this.state.EditUser.EditUserError =
          "Fout bij het resetten van Multi-Factor Authenticatie (MFA).";
        callback(this.state);
        setTimeout(() => {
          this.state.EditUser.EditUserError = "";
          callback(this.state);
        }, 5000);
      });
  }
}
