import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import Button from "@shared/components/Button";
import ButtonList from "@shared/components/ButtonList";
import CheckBox from "@shared/components/CheckBox";
import DropDown from "@shared/components/DropDown";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import {
  IndexToRoleEnum,
  RoleEnumToDropDown,
  RoleEnumToIndex,
  RoleProjectEnumToDropDown,
  convertToDutchDateTime,
} from "@shared/utils/helperFunctions";
import React from "react";
import { FiSlash } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./EditUserScreen.css";

interface EditUserScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class EditUserScreen extends React.Component<EditUserScreenProps> {
  render() {
    return (
      <div id="EditUserScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Gebruikers",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.UserList,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title:
                this.props.state.EditUser.firstName +
                " " +
                this.props.state.EditUser.lastName,
            },
          ]}
        ></BreadCrumbs>

        <Section>
          {(() => {
            if (this.props.state.EditUser.EditUserError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.EditUser.EditUserError}
                ></Alert>
              );
            }
            if (this.props.state.EditUser.newPasswordinfo !== "") {
              return (
                <Alert
                  Error={false}
                  Message={this.props.state.EditUser.newPasswordinfo}
                ></Alert>
              );
            }
          })()}
          <table className="EditUserScreen-Table">
            <tbody>
              <tr>
                <td>Voornaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Voornaam"
                    value={this.props.state.EditUser.firstName}
                    onChange={(newvalue) => {
                      this.props.stateHandler.EditUserForm(
                        { firstName: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Achternaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Achternaam"
                    onChange={(newvalue) => {
                      this.props.stateHandler.EditUserForm(
                        { lastName: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.EditUser.lastName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Email*</td>
                <td>
                  <TextInput
                    type={TextInputType.Email}
                    placeholder="Email"
                    value={this.props.state.EditUser.email}
                    onChange={(newvalue) => {
                      this.props.stateHandler.EditUserForm(
                        { email: newvalue },
                        this.props.updateStateCallback
                      );
                    }}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Rol*</td>
                <td>
                  <DropDown
                    list={RoleEnumToDropDown()}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.EditUserForm(
                        { role: IndexToRoleEnum(newKey) },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={RoleEnumToIndex(
                      this.props.state.EditUser.role
                    )}
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <td>Multi-Factor Authenticatie (MFA)</td>
                <td>
                  {(() => {
                    if (this.props.state.EditUser.mfaVerified) {
                      return (
                        <>
                          Ingeschakeld &nbsp;
                          <Button
                            onClick={() => {
                              this.props.stateHandler.ResetUserMFA(
                                this.props.updateStateCallback
                              );
                              this.props.updateStateCallback(this.props.state);
                            }}
                          >
                            MFA resetten
                          </Button>
                        </>
                      );
                    } else {
                      return "Uitgeschakeld";
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td>Forceer MFA</td>
                <td>
                  <CheckBox
                    checked={this.props.state.EditUser.mfaForced}
                    onClick={() => {
                      this.props.stateHandler.EditUserForceMfa(
                        !this.props.state.EditUser.mfaForced,
                        this.props.updateStateCallback
                      );
                    }}
                  ></CheckBox>
                </td>
              </tr>
              <tr>
                <td>Laatst online</td>
                <td>
                  {" "}
                  {convertToDutchDateTime(
                    this.props.state.EditUser.lastLogin?.toString()
                  )}
                </td>
              </tr>
              <tr>
                <td>Geblokkeerd</td>
                <td>
                  {this.props.state.EditUser.blocked ? (
                    <FiSlash />
                  ) : (
                    "Niet geblokkeerd"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <ButtonList
            data={[
              {
                title: "Wijzigingen opslaan",
                callback: () => {
                  this.props.stateHandler.EditUser(
                    this.props.updateStateCallback
                  );
                  this.props.updateStateCallback(this.props.state);
                },
              },
              {
                title: this.props.state.EditUser.blocked
                  ? "Gebruiker deblokkeren"
                  : "Gebruiker blokkeren",
                callback: () => {
                  this.props.stateHandler.BlockUser(
                    this.props.updateStateCallback
                  );
                  this.props.updateStateCallback(this.props.state);
                },
              },
              {
                title: "Gebruiker verwijderen",
                callback: () => {
                  this.props.stateHandler.DeleteUser(
                    this.props.updateStateCallback
                  );
                  this.props.updateStateCallback(this.props.state);
                },
              },
              {
                title: "Wachtwoord resetten",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.ResetPasswordUser,
                    this.props.updateStateCallback
                  );
                },
              },
              {
                title: "Annuleren",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.UserList,
                    this.props.updateStateCallback
                  );
                },
              },
            ]}
          ></ButtonList>
        </Section>
        <Section>
          <SectionTitle subtitle={true}>Deelgebieden</SectionTitle>
          {(() => {
            if (this.props.state.EditUser.subregions_message !== "") {
              return (
                <Alert
                  Error={false}
                  Message={this.props.state.EditUser.subregions_message}
                ></Alert>
              );
            }
          })()}
          <table className="EditUserScreen-Table">
            <tbody>
              {this.props.state.EditUser.subregions.map((item) => {
                return (
                  <tr key={item.subregionId}>
                    <td>{item.subregionDescription}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.EditSubregionsAccess(
                            item.subregionId,
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <ButtonList
            data={[
              {
                title: "Wijzigingen opslaan",
                callback: () => {
                  this.props.stateHandler.saveUserSubregions(
                    this.props.updateStateCallback
                  );
                },
              },
              {
                title: "Annuleren",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.UserList,
                    this.props.updateStateCallback
                  );
                },
              },
            ]}
          ></ButtonList>
        </Section>

        <Section>
          <SectionTitle subtitle={true}>Rapporten met autorisatie</SectionTitle>

          {(() => {
            if (this.props.state.EditUser.templateReports_message !== "") {
              return (
                <Alert
                  Error={false}
                  Message={this.props.state.EditUser.templateReports_message}
                ></Alert>
              );
            }
          })()}
          <table className="EditUserScreen-Table">
            <tbody>
              {this.props.state.EditUser.templateReports.map((item) => {
                return (
                  <tr key={item.templateReportId}>
                    <td>{item.templateReportName}</td>
                    <td>
                      <CheckBox
                        checked={item.access}
                        onClick={() => {
                          this.props.stateHandler.EditTemplateReportsAccess(
                            item.templateReportId,
                            this.props.updateStateCallback
                          );
                        }}
                      ></CheckBox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <ButtonList
            data={[
              {
                title: "Wijzigingen opslaan",
                callback: () => {
                  this.props.stateHandler.saveUserTemplateReports(
                    this.props.updateStateCallback
                  );
                },
              },
              {
                title: "Annuleren",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.UserList,
                    this.props.updateStateCallback
                  );
                },
              },
            ]}
          ></ButtonList>
        </Section>

        <Section>
          <SectionTitle subtitle={true}>Projectrechten</SectionTitle>
          {(() => {
            if (this.props.state.EditUser.projectsRoles_message !== "") {
              return (
                <Alert
                  Error={false}
                  Message={this.props.state.EditUser.projectsRoles_message}
                ></Alert>
              );
            }
          })()}

          <table className="EditUserScreen-Table">
            <thead>
              <tr>
                <td>Toepassen op alle projecten</td>
                <td>
                  <DropDown
                    list={[
                      {
                        key: -1,
                        name: "--",
                        disabled: true,
                      },
                      ...RoleProjectEnumToDropDown(),
                    ]}
                    onChange={(newKey: number) => {
                      this.props.stateHandler.EditAllProjectRoles(
                        IndexToRoleEnum(newKey),
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={
                      this.props.state.EditUser.allProjectRolesDropdown
                        ? RoleEnumToIndex(
                            this.props.state.EditUser.allProjectRolesDropdown
                          )
                        : -1
                    }
                  ></DropDown>
                </td>
              </tr>
              <tr>
                <th>Project</th>
                <th>Rechten</th>
              </tr>
            </thead>
            <tbody>
              {this.props.state.EditUser.projectsRoles.map((item) => {
                return (
                  <tr key={item.projectId}>
                    <td>{item.projectName}</td>
                    <td>
                      <DropDown
                        list={RoleProjectEnumToDropDown()}
                        onChange={(newKey: number) => {
                          this.props.stateHandler.EditProjectsRoles(
                            item.projectId,
                            IndexToRoleEnum(newKey),
                            this.props.updateStateCallback
                          );
                        }}
                        selectedKey={RoleEnumToIndex(item.role)}
                      ></DropDown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <ButtonList
            data={[
              {
                title: "Wijzigingen opslaan",
                callback: () => {
                  this.props.stateHandler.saveUserProjectsRoles(
                    this.props.updateStateCallback
                  );
                },
              },
              {
                title: "Annuleren",
                callback: () => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.UserList,
                    this.props.updateStateCallback
                  );
                },
              },
            ]}
          ></ButtonList>
        </Section>
      </div>
    );
  }
}
