import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./NewModelScreen.css";

interface NewModelScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewModelScreen extends React.Component<NewModelScreenProps> {
  render() {
    return (
      <div id="NewModelScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Nieuw rekenmodel",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={this.props.state.newModelScreen.loadingForm}>
          {(() => {
            if (this.props.state.newModelScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.newModelScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="NewModelScreen-Table">
            <tbody>
              <tr>
                <td>Naam rekenmodel*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Naam rekenmodel"
                    onChange={(newValue) => {
                      this.props.stateHandler.newModelUpdateForm(
                        { modelName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newModelScreen.modelName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newValue) => {
                      this.props.stateHandler.newModelUpdateForm(
                        { description: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newModelScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Type rekenmodel*</td>
                <td>
                  <DropDown
                    list={this.props.state.newModelScreen.modelTypes}
                    onChange={(newKey) => {
                      this.props.stateHandler.newModelUpdateForm(
                        { modelType: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={this.props.state.newModelScreen.modelType}
                  ></DropDown>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.newModelSave(
                  this.props.updateStateCallback
                );
              },
              disabled: this.props.state.newModelScreen.loadingForm,
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
