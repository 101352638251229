import { Configuration, RoleEnum } from "@shared/client/lib";
import { AttachmentVersionModelApi } from "@shared/client/lib/apis/AttachmentVersionModelApi";
import { AuthApi } from "@shared/client/lib/apis/AuthApi";
import { CategoryAttachmentApi } from "@shared/client/lib/apis/CategoryAttachmentApi";
import { DocumentationApi } from "@shared/client/lib/apis/DocumentationApi";
import { GrexUserAdminApi } from "@shared/client/lib/apis/GrexUserAdminApi";
import { GrexUserMeApi } from "@shared/client/lib/apis/GrexUserMeApi";
import { LinkBIApi } from "@shared/client/lib/apis/LinkBIApi";
import { LogApi } from "@shared/client/lib/apis/LogApi";
import { MapViewerApi } from "@shared/client/lib/apis/MapViewerApi";
import { ModelApi } from "@shared/client/lib/apis/ModelApi";
import { PhaseApi } from "@shared/client/lib/apis/PhaseApi";
import { ProjectApi } from "@shared/client/lib/apis/ProjectApi";
import { ReportApi } from "@shared/client/lib/apis/ReportApi";
import { StatusApi } from "@shared/client/lib/apis/StatusApi";
import { SubregionApi } from "@shared/client/lib/apis/SubregionApi";
import { TemplateModelApi } from "@shared/client/lib/apis/TemplateModelApi";
import { TemplateReportApi } from "@shared/client/lib/apis/TemplateReportApi";
import { TokenApi } from "@shared/client/lib/apis/TokenApi";
import { TypeProjectApi } from "@shared/client/lib/apis/TypeProjectApi";
import { VersionModelApi } from "@shared/client/lib/apis/VersionModelApi";
import { jwtDecode } from "jwt-decode";
import { initialAppStateString } from "./InitialAppState";
import { AppStateType, LoginState, ScreenState } from "./Types";
import { CreateUserStateHandler } from "./screens/admin/CreateUserStateHandler";
import { EditUserStateHandler } from "./screens/admin/EditUserStateHandler";
import { ResetPasswordUserStateHandler } from "./screens/admin/ResetPasswordUserStateHandler";
import { UserListStateHandler } from "./screens/admin/UserListStateHandler";
import { LoginMfaStateHandler } from "./screens/auth/LoginMfaStateHandler";
import { LoginSamlStateHandler } from "./screens/auth/LoginSamlStateHandler";
import { LoginStateHandler } from "./screens/auth/LoginStateHandler";
import { ResetPasswordCodeStateHandler } from "./screens/auth/ResetPasswordCodeStateHandler";
import { ResetPasswordEmailStateHandler } from "./screens/auth/ResetPasswordEmailStateHandler";
import { ResetPasswordMfaStateHandler } from "./screens/auth/ResetPasswordMfaStateHandler";
import { ResetPasswordNewPasswordStateHandler } from "./screens/auth/ResetPasswordNewPasswordStateHandler";
import { CategoryAttachmentStateHandler } from "./screens/configuration/CategoryAttachmentStateHandler";
import { PhaseStateHandler } from "./screens/configuration/PhaseStateHandler";
import { SubregionStateHandler } from "./screens/configuration/SubregionStateHandler";
import { TypeProjectStateHandler } from "./screens/configuration/TypeProjectStateHandler";
import { DocumentationStateHandler } from "./screens/docs/DocumentationStateHandler";
import { LogStateHandler } from "./screens/log/LogStateHandler";
import { ClientMapStateHandler } from "./screens/mapviewer/ClientMapStateHandler";
import { OverviewStateHandler } from "./screens/mapviewer/OverviewStateHandler";
import { ModelInfoStateHandler } from "./screens/project/ModelInfoStateHandler";
import { NewModelStateHandler } from "./screens/project/NewModelStateHandler";
import { NewProjectStateHandler } from "./screens/project/NewProjectStateHandler";
import { NewVersionModelStateHandler } from "./screens/project/NewVersionModelStateHandler";
import { ProjectInfoStateHandler } from "./screens/project/ProjectInfoStateHandler";
import { ProjectStateHandler } from "./screens/project/ProjectStateHandler";
import { VersionModelInfoStateHandler } from "./screens/project/VersionModelInfoStateHandler";
import { VersionModelNewAttachmentStateHandler } from "./screens/project/VersionModelNewAttachmentStateHandler";
import { BiLinksStateHandler } from "./screens/report/BiLinksStateHandler";
import { NewBiLinkStateHandler } from "./screens/report/NewBiLinkStateHandler";
import { ReportStateHandler } from "./screens/report/ReportStateHandler";
import { SetupMfaStateHandler } from "./screens/user/SetupMfaStateHandler";
import { UserStateHandler } from "./screens/user/UserStateHandler";
export class AppStateHandler
  implements
    TypeProjectStateHandler,
    SubregionStateHandler,
    CategoryAttachmentStateHandler,
    UserStateHandler,
    PhaseStateHandler,
    ModelInfoStateHandler,
    LoginStateHandler,
    ReportStateHandler,
    ProjectStateHandler,
    NewProjectStateHandler,
    ProjectInfoStateHandler,
    NewVersionModelStateHandler,
    VersionModelInfoStateHandler,
    VersionModelNewAttachmentStateHandler,
    NewModelStateHandler,
    LogStateHandler,
    ClientMapStateHandler,
    OverviewStateHandler,
    NewBiLinkStateHandler,
    BiLinksStateHandler,
    LoginSamlStateHandler
{
  public state: AppStateType = JSON.parse(initialAppStateString);

  public basePath: () => string = () => {
    let basePath: string = window.location.href;
    basePath = basePath.replace("localhost:3000", "localhost:8000");
    // Remove trailing slash if any
    if (basePath.substring(basePath.length - 1) === "/") {
      basePath = basePath.substring(0, basePath.length - 1);
    }
    return basePath;
  };

  // API
  private apiConfiguration = new Configuration({
    basePath: this.basePath(),
    accessToken: () => {
      return this.state.currentUser.accessToken || "";
    },
    // credentials: "include",
  });
  public authApi = new AuthApi(this.apiConfiguration);
  public tokenApi = new TokenApi(this.apiConfiguration);
  public typeProjectApi = new TypeProjectApi(this.apiConfiguration);
  public subregionApi = new SubregionApi(this.apiConfiguration);
  public phaseApi = new PhaseApi(this.apiConfiguration);
  public projectApi = new ProjectApi(this.apiConfiguration);
  public versionModelApi = new VersionModelApi(this.apiConfiguration);
  public attachmentVersionModelApi = new AttachmentVersionModelApi(
    this.apiConfiguration
  );
  public modelApi = new ModelApi(this.apiConfiguration);
  public templateModelApi = new TemplateModelApi(this.apiConfiguration);
  public userApi = new GrexUserMeApi(this.apiConfiguration);
  public userAdminApi = new GrexUserAdminApi(this.apiConfiguration);
  public logApi = new LogApi(this.apiConfiguration);
  public categoryAttachmentApi = new CategoryAttachmentApi(
    this.apiConfiguration
  );
  public templateReportApi = new TemplateReportApi(this.apiConfiguration);
  public documentationApi = new DocumentationApi(this.apiConfiguration);
  public mapViewerApi = new MapViewerApi(this.apiConfiguration);
  public reportApi = new ReportApi(this.apiConfiguration);
  public linkBiApi = new LinkBIApi(this.apiConfiguration);
  public statusApi = new StatusApi(this.apiConfiguration);

  public getState = () => {
    return this.state;
  };

  public initApp = (callback: (newState: AppStateType) => void) => {
    this.tokenApi
      .apiGrexmanagerTokenRefreshRetrieve()
      .then((response) => {
        if (response.access !== null) {
          this.setAccessToken(response.access, callback);

          // Get the role
          this.userApi
            .apiGrexmanagerUserMeRetrieve()
            .then((response) => {
              this.state.currentUser.isSuperAdmin = response.isStaff || false;
              this.state.currentUser.userRole = response.role || RoleEnum.Non;
              this.state.loggedIn = true;

              this.changeScreen(ScreenState.Projects, callback);
            })
            .catch((error) => {
              console.log(error);
              this.state = JSON.parse(initialAppStateString); //sign out
              callback(this.state);
              this.initApp(callback);
            });
        } else {
          this.goToLoginScreen(callback);
        }
      })
      .catch((error) => {
        console.log(error);
        this.goToLoginScreen(callback);
      });
  };

  public goToLoginScreen = (callback: (newState: AppStateType) => void) => {
    // Find out which login screen to show
    this.statusApi
      .apiGrexmanagerClientStatusRetrieve()
      .then((response) => {
        if (response.saml === true) {
          this.state.loginState = LoginState.LoginSaml;
          callback(this.state);
        } else {
          this.state.loginState = LoginState.LoginEmailPassword;
          callback(this.state);
        }
      })
      .catch((error) => {
        console.log(error);
        this.state.loginState = LoginState.LoginEmailPassword;
        callback(this.state);
      });
  };

  public logout = (callback: (newState: AppStateType) => void) => {
    // First inform the back-end that this user has logged out.
    this.authApi
      .apiGrexmanagerUserLogoutCreate()
      .then((response) => {
        console.log("Logged out");
      })
      .catch((error) => {
        console.log(error);
      });

    // Next, reset the state of the front-end.
    this.state = JSON.parse(initialAppStateString);
    callback(this.state);
    this.initApp(callback);
  };

  public changeScreen = (
    newScreenState: ScreenState,
    callback: (newState: AppStateType) => void
  ) => {
    if (
      this.state.currentUser.mfaForced &&
      !this.state.currentUser.mfaVerified
    ) {
      this.state.screenState = ScreenState.SetupMfa;
      this.loadMfaQrCode(callback);
      return;
    }
    if (newScreenState === ScreenState.Projects) {
      this.initProjectScreen(callback);
      this.closeOverviewMapScreen(callback);
    } else if (newScreenState === ScreenState.Projects_NewProject) {
      this.loadNewProjectScreen(callback);
    } else if (newScreenState === ScreenState.Projects_NewVersionModel) {
      this.loadNewVersionModelScreen(callback);
    } else if (
      newScreenState === ScreenState.Projects_VersionModelInfo_NewAttachment
    ) {
      this.loadVersionModelNewAttachmentScreen(callback);
    } else if (newScreenState === ScreenState.Projects_NewModel) {
      this.loadNewModelScreen(callback);
    } else if (newScreenState === ScreenState.Reports) {
      this.initReportScreen(callback);
    } else if (newScreenState === ScreenState.Configuration_PhaseDefinitions) {
      this.loadPhaseList(callback);
    } else if (newScreenState === ScreenState.Configuration_Subregions) {
      this.loadSubregionList(callback);
    } else if (newScreenState === ScreenState.Configuration_TypeProject) {
      this.loadTypeProjectList(callback);
    } else if (newScreenState === ScreenState.Configuration_FileCategories) {
      this.loadCategoryAttachmentList(callback);
    } else if (newScreenState === ScreenState.Logs) {
      this.initLogScreen(callback);
    } else if (newScreenState === ScreenState.User) {
      this.loadUserinfo(callback);
    } else if (newScreenState === ScreenState.SetupMfa) {
      this.loadMfaQrCode(callback);
    } else if (newScreenState === ScreenState.UserList) {
      this.loadUserList(callback);
    } else if (newScreenState === ScreenState.EditUser) {
      this.loadUser(callback);
    } else if (newScreenState === ScreenState.CreateUser) {
      this.loadCreateUser(callback);
    } else if (newScreenState === ScreenState.Documentation) {
      this.loadDocumentation(callback);
    } else if (newScreenState === ScreenState.MapViewer_Client) {
      this.initClientMapScreen(callback);
    } else if (newScreenState === ScreenState.MapViewer_Overview) {
      this.initOverviewMapScreen(callback);
    } else if (newScreenState === ScreenState.NewBiLink) {
      this.initNewBiLinkScreen(callback);
    } else if (newScreenState === ScreenState.BiLinks) {
      this.initBiLinksScreen(callback);
    } else {
      this.state.screenState = newScreenState;
      callback(this.state);
    }
  };

  public changeLoginScreen = (
    newScreenState: LoginState,
    callback: (newState: AppStateType) => void
  ) => {
    this.state.loginState = newScreenState;
    callback(this.state);
  };

  public setAccessToken = (
    accessToken: string,
    callback: (newState: AppStateType) => void
  ) => {
    this.state.currentUser.accessToken = accessToken;
    callback(this.state);

    // Decode the access token
    const decoded = jwtDecode(this.state.currentUser.accessToken) as {
      [key: string]: any;
    };
    if ("exp" in decoded) {
      const secondsMargin = 30; // 30 seconds before token expires a refresh request will be made
      const currentUnixTime = Math.floor(Date.now() / 1000);
      const secondsLeft = decoded.exp - currentUnixTime;
      const refreshInMs = Math.max((secondsLeft - secondsMargin) * 1000, 1);

      // Set a timer to refresh the access token
      setTimeout(() => {
        this.tokenApi
          .apiGrexmanagerTokenRefreshRetrieve()
          .then((response) => {
            if (response.access !== null) {
              this.setAccessToken(response.access, callback);
            } else {
              //sign out
              this.state = JSON.parse(initialAppStateString);
              callback(this.state);
              this.initApp(callback);
            }
          })
          .catch((error) => {
            console.log(error);
            //sign out
            this.state = JSON.parse(initialAppStateString);
            callback(this.state);
            this.initApp(callback);
          });
      }, refreshInMs);
    }
  };

  // Login screen
  login = LoginStateHandler.prototype.login;

  // Log screen
  initLogScreen = LogStateHandler.prototype.initLogScreen;

  // Report screen
  reportScreenSortProjectList =
    ReportStateHandler.prototype.reportScreenSortProjectList;
  initReportScreen = ReportStateHandler.prototype.initReportScreen;
  reportScreenReloadProjects =
    ReportStateHandler.prototype.reportScreenReloadProjects;
  reportScreenReloadVersionModels =
    ReportStateHandler.prototype.reportScreenReloadVersionModels;
  reportScreenReloadModels =
    ReportStateHandler.prototype.reportScreenReloadModels;
  reportScreenOnProjectFilterChange =
    ReportStateHandler.prototype.reportScreenOnProjectFilterChange;
  reportScreenFilterProjectListAndSelectFirst =
    ReportStateHandler.prototype.reportScreenFilterProjectListAndSelectFirst;
  reportScreenUpdateProjectSorting =
    ReportStateHandler.prototype.reportScreenUpdateProjectSorting;
  reportScreenOnProjectClick =
    ReportStateHandler.prototype.reportScreenOnProjectClick;
  reportScreenOnVersionModelClick =
    ReportStateHandler.prototype.reportScreenOnVersionModelClick;
  reportScreenToggleModel =
    ReportStateHandler.prototype.reportScreenToggleModel;
  reportScreenSelectTemplate =
    ReportStateHandler.prototype.reportScreenSelectTemplate;
  reportScreenCheckTemplate =
    ReportStateHandler.prototype.reportScreenCheckTemplate;
  reportScreenMakeReport = ReportStateHandler.prototype.reportScreenMakeReport;
  reportScreenSelectAfterLoad =
    ReportStateHandler.prototype.reportScreenSelectAfterLoad;
  checkIfAllProjectsAreSelected =
    ReportStateHandler.prototype.checkIfAllProjectsAreSelected;
  toggleSelectAllProjectsButton =
    ReportStateHandler.prototype.toggleSelectAllProjectsButton;

  // Project screen
  initProjectScreen = ProjectStateHandler.prototype.initProjectScreen;
  projectScreenReloadProjects =
    ProjectStateHandler.prototype.projectScreenReloadProjects;
  projectScreenReloadVersionModels =
    ProjectStateHandler.prototype.projectScreenReloadVersionModels;
  projectScreenReloadModels =
    ProjectStateHandler.prototype.projectScreenReloadModels;

  sortProjectList = ProjectStateHandler.prototype.sortProjectList;
  onProjectFilterChange = ProjectStateHandler.prototype.onProjectFilterChange;
  filterProjectListAndSelectFirst =
    ProjectStateHandler.prototype.filterProjectListAndSelectFirst;
  updateProjectSorting = ProjectStateHandler.prototype.updateProjectSorting;

  onProjectClick = ProjectStateHandler.prototype.onProjectClick;
  onVersionModelClick = ProjectStateHandler.prototype.onVersionModelClick;
  onModelClick = ProjectStateHandler.prototype.onModelClick;

  onProjectMoreInfoClick = ProjectStateHandler.prototype.onProjectMoreInfoClick;
  onProjectDuplicateClick =
    ProjectStateHandler.prototype.onProjectDuplicateClick;
  onProjectDeleteClick = ProjectStateHandler.prototype.onProjectDeleteClick;

  onVersionModelMoreInfoClick =
    ProjectStateHandler.prototype.onVersionModelMoreInfoClick;
  onVersionModelDuplicateClick =
    ProjectStateHandler.prototype.onVersionModelDuplicateClick;
  onVersionModelDeleteClick =
    ProjectStateHandler.prototype.onVersionModelDeleteClick;
  onVersionModelAddAttachmentClick =
    ProjectStateHandler.prototype.onVersionModelAddAttachmentClick;

  onModelMoreInfoClick = ProjectStateHandler.prototype.onModelMoreInfoClick;
  onModelDuplicateClick = ProjectStateHandler.prototype.onModelDuplicateClick;
  onModelDeleteClick = ProjectStateHandler.prototype.onModelDeleteClick;
  onModelCancelSessionEditClick =
    ProjectStateHandler.prototype.onModelCancelSessionEditClick;
  permanantlyDeleteProject =
    ProjectStateHandler.prototype.permanantlyDeleteProject;
  permanantlyDeleteVersionModel =
    ProjectStateHandler.prototype.permanantlyDeleteVersionModel;
  permanantlyDeleteModel = ProjectStateHandler.prototype.permanantlyDeleteModel;
  permanentlyCancelModelSessionEdit =
    ProjectStateHandler.prototype.permanentlyCancelModelSessionEdit;

  openProjectScreenSubMenu =
    ProjectStateHandler.prototype.openProjectScreenSubMenu;
  closeProjectScreenSubMenu =
    ProjectStateHandler.prototype.closeProjectScreenSubMenu;

  onModelMoveClick = ProjectStateHandler.prototype.onModelMoveClick;
  onVersionModelMoveClick =
    ProjectStateHandler.prototype.onVersionModelMoveClick;
  onMoveProjectClick = ProjectStateHandler.prototype.onMoveProjectClick;
  onMoveVersionModelClick =
    ProjectStateHandler.prototype.onMoveVersionModelClick;
  closeMoveDialog = ProjectStateHandler.prototype.closeMoveDialog;
  moveVersionModel = ProjectStateHandler.prototype.moveVersionModel;
  moveModel = ProjectStateHandler.prototype.moveModel;
  moveVersionModelBack = ProjectStateHandler.prototype.moveVersionModelBack;
  openModel = ProjectStateHandler.prototype.openModel;

  // New project screen
  loadNewProjectScreen = NewProjectStateHandler.prototype.loadNewProjectScreen;
  newProjectUpdateForm = NewProjectStateHandler.prototype.newProjectUpdateForm;
  newProjectSubregionChanged =
    NewProjectStateHandler.prototype.newProjectSubregionChanged;
  newProjectChangeRole = NewProjectStateHandler.prototype.newProjectChangeRole;
  newProjectSave = NewProjectStateHandler.prototype.newProjectSave;

  // project info screen
  loadProjectInfoScreen =
    ProjectInfoStateHandler.prototype.loadProjectInfoScreen;
  projectInfoUpdateForm =
    ProjectInfoStateHandler.prototype.projectInfoUpdateForm;
  projectInfoSubregionChanged =
    ProjectInfoStateHandler.prototype.projectInfoSubregionChanged;
  projectInfoChangeRole =
    ProjectInfoStateHandler.prototype.projectInfoChangeRole;
  projectInfoSave = ProjectInfoStateHandler.prototype.projectInfoSave;

  // New version screen
  loadNewVersionModelScreen =
    NewVersionModelStateHandler.prototype.loadNewVersionModelScreen;
  newVersionModelUpdateForm =
    NewVersionModelStateHandler.prototype.newVersionModelUpdateForm;
  newVersionModelSave =
    NewVersionModelStateHandler.prototype.newVersionModelSave;

  // VersionModel info screen
  loadVersionModelInfoScreen =
    VersionModelInfoStateHandler.prototype.loadVersionModelInfoScreen;
  versionInfoUpdateForm =
    VersionModelInfoStateHandler.prototype.versionInfoUpdateForm;
  versionInfoSave = VersionModelInfoStateHandler.prototype.versionInfoSave;
  permanantlyVersionInfoDeleteAttachment =
    VersionModelInfoStateHandler.prototype
      .permanantlyVersionInfoDeleteAttachment;
  versionInfoDownloadAttachment =
    VersionModelInfoStateHandler.prototype.versionInfoDownloadAttachment;

  // VersionModel new attachement screen
  loadVersionModelNewAttachmentScreen =
    VersionModelNewAttachmentStateHandler.prototype
      .loadVersionModelNewAttachmentScreen;
  updateVersionModelNewAttachmentScreen =
    VersionModelNewAttachmentStateHandler.prototype
      .updateVersionModelNewAttachmentScreen;
  saveVersionModelNewAttachmentScreen =
    VersionModelNewAttachmentStateHandler.prototype
      .saveVersionModelNewAttachmentScreen;

  // New calc model screen
  loadNewModelScreen = NewModelStateHandler.prototype.loadNewModelScreen;
  newModelUpdateForm = NewModelStateHandler.prototype.newModelUpdateForm;
  newModelSave = NewModelStateHandler.prototype.newModelSave;

  // Calc model info screen
  loadModelInfoScreen = ModelInfoStateHandler.prototype.loadModelInfoScreen;
  modelInfoUpdateForm = ModelInfoStateHandler.prototype.modelInfoUpdateForm;
  modelInfoChangeMutationName =
    ModelInfoStateHandler.prototype.modelInfoChangeMutationName;
  modelInfoSave = ModelInfoStateHandler.prototype.modelInfoSave;
  activateModelHistory = ModelInfoStateHandler.prototype.activateModelHistory;
  downloadModelHistory = ModelInfoStateHandler.prototype.downloadModelHistory;
  updateModelHistoryFile =
    ModelInfoStateHandler.prototype.updateModelHistoryFile;
  saveModelHistoryFile = ModelInfoStateHandler.prototype.saveModelHistoryFile;
  loadModelHistory = ModelInfoStateHandler.prototype.loadModelHistory;

  // Phase screen
  loadPhaseList = PhaseStateHandler.prototype.loadPhaseList;
  onPhaseDeleteClick = PhaseStateHandler.prototype.onPhaseDeleteClick;
  onPhaseEditInputChange = PhaseStateHandler.prototype.onPhaseEditInputChange;
  onPhaseEditClick = PhaseStateHandler.prototype.onPhaseEditClick;
  onNewPhaseInputChange = PhaseStateHandler.prototype.onNewPhaseInputChange;
  onPhaseAddClick = PhaseStateHandler.prototype.onPhaseAddClick;

  // Subregion screen
  loadSubregionList = SubregionStateHandler.prototype.loadSubregionList;
  onSubregionDeleteClick =
    SubregionStateHandler.prototype.onSubregionDeleteClick;
  onSubregionEditInputChange =
    SubregionStateHandler.prototype.onSubregionEditInputChange;
  onSubregionEditClick = SubregionStateHandler.prototype.onSubregionEditClick;
  onNewSubregionInputChange =
    SubregionStateHandler.prototype.onNewSubregionInputChange;
  onSubregionAddClick = SubregionStateHandler.prototype.onSubregionAddClick;
  onEditMapClick = SubregionStateHandler.prototype.onEditMapClick;
  closeAlert = SubregionStateHandler.prototype.closeAlert;
  permanantlyDeleteSubregion =
    SubregionStateHandler.prototype.permanantlyDeleteSubregion;

  // TypeProject screen
  loadTypeProjectList = TypeProjectStateHandler.prototype.loadTypeProjectList;
  onTypeProjectDeleteClick =
    TypeProjectStateHandler.prototype.onTypeProjectDeleteClick;
  onTypeProjectEditInputChange =
    TypeProjectStateHandler.prototype.onTypeProjectEditInputChange;
  onTypeProjectEditClick =
    TypeProjectStateHandler.prototype.onTypeProjectEditClick;
  onNewTypeProjectInputChange =
    TypeProjectStateHandler.prototype.onNewTypeProjectInputChange;
  onTypeProjectAddClick =
    TypeProjectStateHandler.prototype.onTypeProjectAddClick;
  closeDeleteTypeProjectAlert =
    TypeProjectStateHandler.prototype.closeDeleteTypeProjectAlert;
  permanantlyDeleteTypeProject =
    TypeProjectStateHandler.prototype.permanantlyDeleteTypeProject;

  // CategoryAttachment screen
  loadCategoryAttachmentList =
    CategoryAttachmentStateHandler.prototype.loadCategoryAttachmentList;
  onCategoryAttachmentDeleteClick =
    CategoryAttachmentStateHandler.prototype.onCategoryAttachmentDeleteClick;
  onCategoryAttachmentEditInputChange =
    CategoryAttachmentStateHandler.prototype
      .onCategoryAttachmentEditInputChange;
  onCategoryAttachmentEditClick =
    CategoryAttachmentStateHandler.prototype.onCategoryAttachmentEditClick;
  onNewCategoryAttachmentInputChange =
    CategoryAttachmentStateHandler.prototype.onNewCategoryAttachmentInputChange;
  onCategoryAttachmentAddClick =
    CategoryAttachmentStateHandler.prototype.onCategoryAttachmentAddClick;

  // User screen
  loadUserinfo = UserStateHandler.prototype.loadUserinfo;
  updateUserForm = UserStateHandler.prototype.updateUserForm;
  updateUser = UserStateHandler.prototype.updateUser;
  changePasswordForm = UserStateHandler.prototype.changePasswordForm;
  vlaidateNewPasswordRepeat =
    UserStateHandler.prototype.vlaidateNewPasswordRepeat;
  changePassword = UserStateHandler.prototype.changePassword;
  disableMFA = UserStateHandler.prototype.disableMFA;

  // Login nfa
  LoginMfaVerifyCode = LoginMfaStateHandler.prototype.LoginMfaVerifyCode;

  // Setup Mfa
  loadMfaQrCode = SetupMfaStateHandler.prototype.loadMfaQrCode;
  VerifyMfaCode = SetupMfaStateHandler.prototype.VerifyMfaCode;

  // Reset password
  ResetPasswordSendEmail =
    ResetPasswordEmailStateHandler.prototype.ResetPasswordSendEmail;
  ResetPasswordSendEmailForm =
    ResetPasswordEmailStateHandler.prototype.ResetPasswordSendEmailForm;
  ResetPasswordVerifyCodeForm =
    ResetPasswordCodeStateHandler.prototype.ResetPasswordVerifyCodeForm;
  ResetPasswordVerifyCode =
    ResetPasswordCodeStateHandler.prototype.ResetPasswordVerifyCode;
  ResetPasswordVerifyMfaCode =
    ResetPasswordMfaStateHandler.prototype.ResetPasswordVerifyMfaCode;
  ResetPasswordVlaidateNewPasswordRepeat =
    ResetPasswordNewPasswordStateHandler.prototype
      .ResetPasswordVlaidateNewPasswordRepeat;
  ResetPasswordSetNewPassword =
    ResetPasswordNewPasswordStateHandler.prototype.ResetPasswordSetNewPassword;

  ResetPasswordNewPasswordForm =
    ResetPasswordNewPasswordStateHandler.prototype.ResetPasswordNewPasswordForm;

  // Admin / users list
  loadUserList = UserListStateHandler.prototype.loadUserList;
  SelectUser = UserListStateHandler.prototype.SelectUser;
  // Admin / create user
  loadCreateUser = CreateUserStateHandler.prototype.loadCreateUser;
  CreateUserForm = CreateUserStateHandler.prototype.CreateUserForm;
  validateCreateUser = CreateUserStateHandler.prototype.validateCreateUser;
  CreateUser = CreateUserStateHandler.prototype.CreateUser;
  CreateUserLoadSubregions =
    CreateUserStateHandler.prototype.CreateUserLoadSubregions;
  CreateUserLoadTemplateReports =
    CreateUserStateHandler.prototype.CreateUserLoadTemplateReports;
  CreateUserLoadProjects =
    CreateUserStateHandler.prototype.CreateUserLoadProjects;
  CreateUserSubregionAccessUpdateForm =
    CreateUserStateHandler.prototype.CreateUserSubregionAccessUpdateForm;
  CreateUserTemplateReportAccessUpdateForm =
    CreateUserStateHandler.prototype.CreateUserTemplateReportAccessUpdateForm;
  CreateUserProjectRoleUpdateForm =
    CreateUserStateHandler.prototype.CreateUserProjectRoleUpdateForm;
  CreateUserSubregions = CreateUserStateHandler.prototype.CreateUserSubregions;
  CreateUserTemplateReports =
    CreateUserStateHandler.prototype.CreateUserTemplateReports;
  CreateUserProjectsRoles =
    CreateUserStateHandler.prototype.CreateUserProjectsRoles;
  CreateUserAllProjectsRoles =
    CreateUserStateHandler.prototype.CreateUserAllProjectsRoles;

  // Admin / edit user
  loadUser = EditUserStateHandler.prototype.loadUser;
  EditUserForm = EditUserStateHandler.prototype.EditUserForm;
  validateEditUser = EditUserStateHandler.prototype.validateEditUser;
  EditUser = EditUserStateHandler.prototype.EditUser;
  BlockUser = EditUserStateHandler.prototype.BlockUser;
  DeleteUser = EditUserStateHandler.prototype.DeleteUser;
  loadUserSubregions = EditUserStateHandler.prototype.loadUserSubregions;
  loadUserTemplateReports =
    EditUserStateHandler.prototype.loadUserTemplateReports;
  loadUserProjectsRoles = EditUserStateHandler.prototype.loadUserProjectsRoles;
  EditSubregionsAccess = EditUserStateHandler.prototype.EditSubregionsAccess;
  EditTemplateReportsAccess =
    EditUserStateHandler.prototype.EditTemplateReportsAccess;
  EditProjectsRoles = EditUserStateHandler.prototype.EditProjectsRoles;
  EditAllProjectRoles = EditUserStateHandler.prototype.EditAllProjectRoles;

  saveUserSubregions = EditUserStateHandler.prototype.saveUserSubregions;
  saveUserTemplateReports =
    EditUserStateHandler.prototype.saveUserTemplateReports;
  saveUserProjectsRoles = EditUserStateHandler.prototype.saveUserProjectsRoles;
  EditUserResetPasswordForm =
    ResetPasswordUserStateHandler.prototype.EditUserResetPasswordForm;
  EditUserVlaidateNewPasswordRepeat =
    ResetPasswordUserStateHandler.prototype.EditUserVlaidateNewPasswordRepeat;
  EditUserChangePassword =
    ResetPasswordUserStateHandler.prototype.EditUserChangePassword;
  EditUserForceMfa = EditUserStateHandler.prototype.EditUserForceMfa;
  ForceMfaAllUsers = UserListStateHandler.prototype.ForceMfaAllUsers;
  ResetUserMFA = EditUserStateHandler.prototype.ResetUserMFA;

  // Documentation
  loadDocumentation = DocumentationStateHandler.prototype.loadDocumentation;
  OpenPdfFile = DocumentationStateHandler.prototype.OpenPdfFile;

  // MapViewer
  initClientMapScreen = ClientMapStateHandler.prototype.initClientMapScreen;
  initOverviewMapScreen = OverviewStateHandler.prototype.initOverviewMapScreen;
  closeOverviewMapScreen =
    OverviewStateHandler.prototype.closeOverviewMapScreen;

  // New BI Link Screen
  initNewBiLinkScreen = NewBiLinkStateHandler.prototype.initNewBiLinkScreen;
  newBiLinkUpdateForm = NewBiLinkStateHandler.prototype.newBiLinkUpdateForm;
  newBiLinkSave = NewBiLinkStateHandler.prototype.newBiLinkSave;

  // BI Links Screen
  initBiLinksScreen = BiLinksStateHandler.prototype.initBiLinksScreen;
  biLinksScreenGenerateTemporaryCredentials =
    BiLinksStateHandler.prototype.biLinksScreenGenerateTemporaryCredentials;
  biLinksScreenDeleteLink =
    BiLinksStateHandler.prototype.biLinksScreenDeleteLink;

  // Login SAML
  login_saml = LoginSamlStateHandler.prototype.login_saml;
  go_to_login = LoginSamlStateHandler.prototype.go_to_login;
}
