import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./NewBiLinkScreen.css";

interface NewBiLinkScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewBiLinkScreen extends React.Component<NewBiLinkScreenProps> {
  render() {
    return (
      <div id="NewBiLinkScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Rapportages",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Reports,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Nieuwe BI link",
            },
          ]}
        ></BreadCrumbs>

        <Section>
          {(() => {
            if (this.props.state.NewBiLinkScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.NewBiLinkScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="NewBiLinkScreen-Table">
            <tbody>
              <tr>
                <td>Beschrijving*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newValue) => {
                      this.props.stateHandler.newBiLinkUpdateForm(
                        { description: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.NewBiLinkScreen.description}
                  ></TextInput>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.newBiLinkSave(
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Reports,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
