/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.09.10.10.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelCreateRequest
 */
export interface ModelCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateRequest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateRequest
     */
    typeModel: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateRequest
     */
    version: number;
}

/**
 * Check if a given object implements the ModelCreateRequest interface.
 */
export function instanceOfModelCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typeModel" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function ModelCreateRequestFromJSON(json: any): ModelCreateRequest {
    return ModelCreateRequestFromJSONTyped(json, false);
}

export function ModelCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'typeModel': json['type_model'],
        'version': json['version'],
    };
}

export function ModelCreateRequestToJSON(value?: ModelCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'type_model': value.typeModel,
        'version': value.version,
    };
}

