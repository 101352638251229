import { RoleEnum } from "@shared/client/lib";
import { DropDownListItem } from "@shared/components/DropDown";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class NewProjectStateHandler {
  loadNewProjectScreen(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Projects_NewProject;
    this.state.loading = false;
    this.state.newProjectScreen = {
      loadingForm: true,
      loadingRoles: true,
      formError: "",
      projectName: "",
      description: "",
      typeProject: 0,
      projectNumber: "",
      subregion: 0,
      organisation: "",
      projectManager: "",
      userRoles: [],
      typeProjectList: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
      subregions: [
        {
          key: 0,
          name: "Aan het laden...",
          disabled: true,
        },
      ],
    };
    callback(this.state);

    // Get typeProject
    this.typeProjectApi
      .apiGrexmanagerTypeProjectList()
      .then((typeProject) => {
        this.state.newProjectScreen.typeProjectList = typeProject.map((x) => {
          const item: DropDownListItem = {
            key: x.id,
            name: x.description,
            disabled: false,
          };
          return item;
        });
        if (this.state.newProjectScreen.typeProjectList.length > 0) {
          this.state.newProjectScreen.typeProject =
            this.state.newProjectScreen.typeProjectList[0].key;
        }
        this.state.newProjectScreen.loadingForm = false; // TODO: Wait for partial areas
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.newProjectScreen.formError =
          "Projecttypen konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.newProjectScreen.formError = "";
          callback(this.state);
        }, 5000);
      });

    // Get subregions
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        this.state.newProjectScreen.subregions = subregions.map((x) => {
          const item: DropDownListItem = {
            key: x.id,
            name: x.description,
            disabled: false,
          };
          return item;
        });
        if (this.state.newProjectScreen.subregions.length > 0) {
          this.state.newProjectScreen.subregion =
            this.state.newProjectScreen.subregions[0].key;
        }
        this.state.newProjectScreen.loadingForm = false; // TODO: Wait for TypeProject
        callback(this.state);

        // get project roles based on partial area
        if (this.state.newProjectScreen.subregions.length > 0) {
          this.subregionApi
            .apiGrexmanagerSubregionRolesList({
              id: this.state.newProjectScreen.subregions[0].key,
            })
            .then((userRoles) => {
              this.state.newProjectScreen.userRoles = userRoles;
              this.state.newProjectScreen.loadingRoles = false;
              callback(this.state);
            })
            .catch((error) => {
              // TODO: Handle error
              console.log(error);

              this.state.newProjectScreen.formError =
                "Gebruikersrechten voor deelgebieden konden niet geladen worden.";
              callback(this.state);
              setTimeout(() => {
                this.state.newProjectScreen.formError = "";
                callback(this.state);
              }, 5000);
            });
        }
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.newProjectScreen.formError =
          "Deelgebieden konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.newProjectScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  newProjectUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      projectName?: string;
      description?: string;
      typeProject?: number;
      projectNumber?: string;
      organisation?: string;
      projectManager?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.projectName !== undefined) {
      this.state.newProjectScreen.projectName = newFormValues.projectName;
    }
    if (newFormValues.description !== undefined) {
      this.state.newProjectScreen.description = newFormValues.description;
    }
    if (newFormValues.typeProject !== undefined) {
      this.state.newProjectScreen.typeProject = newFormValues.typeProject;
    }
    if (newFormValues.projectNumber !== undefined) {
      this.state.newProjectScreen.projectNumber = newFormValues.projectNumber;
    }
    if (newFormValues.organisation !== undefined) {
      this.state.newProjectScreen.organisation = newFormValues.organisation;
    }
    if (newFormValues.projectManager !== undefined) {
      this.state.newProjectScreen.projectManager = newFormValues.projectManager;
    }
    callback(this.state);
  }

  newProjectSubregionChanged(
    this: AppStateHandler,
    newSubregionId: number,
    callback: (newState: AppStateType) => void
  ) {
    this.state.newProjectScreen.subregion = newSubregionId;
    this.state.newProjectScreen.loadingRoles = true;
    callback(this.state);

    this.subregionApi
      .apiGrexmanagerSubregionRolesList({
        id: this.state.newProjectScreen.subregion,
      })
      .then((userRoles) => {
        this.state.newProjectScreen.userRoles = userRoles;
        this.state.newProjectScreen.loadingRoles = false;
        callback(this.state);
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error);

        this.state.newProjectScreen.formError =
          "Gebruikersrechten voor deelgebieden konden niet geladen worden.";
        callback(this.state);
        setTimeout(() => {
          this.state.newProjectScreen.formError = "";
          callback(this.state);
        }, 5000);
      });
  }

  newProjectChangeRole(
    this: AppStateHandler,
    userId: number,
    newRole: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    const roleObjectIndex = this.state.newProjectScreen.userRoles.findIndex(
      (object) => {
        return object.id === userId;
      }
    );
    if (roleObjectIndex > -1) {
      this.state.newProjectScreen.userRoles[roleObjectIndex].role = newRole;
      callback(this.state);
    }
  }

  newProjectSave(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.newProjectScreen.projectName === "" ||
      this.state.newProjectScreen.typeProject <= 0 ||
      this.state.newProjectScreen.subregion <= 0 ||
      this.state.newProjectScreen.userRoles.length === 0
    ) {
      this.state.newProjectScreen.formError =
        "Vul alle velden in die gemarkeerd zijn met een *";
      callback(this.state);
      setTimeout(() => {
        this.state.newProjectScreen.formError = "";
        callback(this.state);
      }, 5000);
    } else {
      this.state.loading = true;
      callback(this.state);

      this.projectApi
        .apiGrexmanagerProjectCreate({
          projectCreateRequest: {
            description:
              this.state.newProjectScreen.description === ""
                ? null
                : this.state.newProjectScreen.description,
            organisation:
              this.state.newProjectScreen.organisation === ""
                ? null
                : this.state.newProjectScreen.organisation,
            name: this.state.newProjectScreen.projectName,
            number:
              this.state.newProjectScreen.projectNumber === ""
                ? null
                : this.state.newProjectScreen.projectNumber,
            projectmanager:
              this.state.newProjectScreen.projectManager === ""
                ? null
                : this.state.newProjectScreen.projectManager,
            subregion: this.state.newProjectScreen.subregion,
            typeProject: this.state.newProjectScreen.typeProject,
            roles: this.state.newProjectScreen.userRoles.map((x) => {
              return { id: x.id, role: x.role };
            }),
          },
        })
        .then(() => {
          this.state.loading = false;
          callback(this.state);
          this.changeScreen(ScreenState.Projects, callback);
        })
        .catch((error) => {
          console.log(error);
          let message = "Er is een fout opgetreden tijdens het opslaan.";
          if (error.response.status === 409) {
            message = "Er bestaat al een project met deze naam.";
          }
          this.state.newProjectScreen.formError = message;
          this.state.loading = false;
          callback(this.state);
          setTimeout(() => {
            this.state.newProjectScreen.formError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
