import React from "react";
import Modal from "react-modal";
import YouTube from "react-youtube";
import "./YoutubePopup.css";

interface YoutubePopupProps {
  videoUrl: string;
  docName: string;
}

const YoutubePopup: React.FC<YoutubePopupProps> = ({ videoUrl, docName }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getVideoIdFromUrl = (url: string) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w-]+)/g;
    const match = regex.exec(url);
    return match ? match[1] : null;
  };

  return (
    <div>
      <p className="YoutubeLink" onClick={openModal}>
        {docName}
      </p>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 999,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "854px",
            width: "100%",
            height: "100%",
            maxHeight: "480px",
            padding: "0",
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <YouTube
          videoId={getVideoIdFromUrl(videoUrl) || ""}
          opts={{
            width: "849px",
            height: "470px",
            playerVars: {
              autoplay: 0, // You can change this to 0 if you don't want autoplay
            },
          }}
        />
      </Modal>
    </div>
  );
};

export default YoutubePopup;
