import Alert from "@shared/components/Alert";
import ButtonList from "@shared/components/ButtonList";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import SectionTitle from "@shared/components/SectionTitle";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";
import "./ResetPasswordCodeScreen.css";
interface ResetPasswordCodeScreenProps {
  state: AppStateType;
  loginstate: LoginState;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class ResetPasswordCodeScreen extends React.Component<ResetPasswordCodeScreenProps> {
  render() {
    return (
      <div id="ResetPasswordCodeScreen-Container">
        <div id="ResetPasswordCodeScreen-Section">
          <GrexManagerLogo />

          <div id="ResetPasswordCodeScreen-LoginMfaHolder">
            <SectionTitle subtitle={false}>Wachtwoordherstel</SectionTitle>
            <p>
              Uw wachtwoordherstelcode is succesvol verzonden naar{" "}
              {this.props.state.ResetPassword.email}. Voer uw
              wachtwoordherstelcode in om verder te gaan.
            </p>

            {(() => {
              if (this.props.state.ResetPassword.resetpasswordMessage !== "") {
                return (
                  <Alert
                    Error={true}
                    Message={
                      this.props.state.ResetPassword.resetpasswordMessage
                    }
                  ></Alert>
                );
              }
            })()}

            <div className="label">Wachtwoordherstelcode</div>
            <TextInput
              type={TextInputType.Text}
              placeholder="Wachtwoordherstelcode"
              value={this.props.state.ResetPassword.resetPasswordCode}
              onChange={(newValue: string) => {
                this.props.stateHandler.ResetPasswordVerifyCodeForm(
                  newValue,
                  this.props.updateStateCallback
                );
              }}
            ></TextInput>
            <div className="ResetPasswordCodeScreen-buttonbox">
              <ButtonList
                data={[
                  {
                    title: "Doorgaan",
                    callback: () => {
                      this.props.stateHandler.ResetPasswordVerifyCode(
                        this.props.updateStateCallback
                      );
                    },
                  },
                  {
                    title: "Annuleren",
                    callback: () => {
                      this.props.stateHandler.changeLoginScreen(
                        LoginState.LoginEmailPassword,
                        this.props.updateStateCallback
                      );
                    },
                  },
                ]}
              ></ButtonList>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
