import Alert from "@shared/components/Alert";
import BreadCrumbs from "@shared/components/BreadCrumbs";
import ButtonList from "@shared/components/ButtonList";
import DropDown from "@shared/components/DropDown";
import { TextInput, TextInputType } from "@shared/components/TextInput";
import React from "react";
import { AppStateHandler } from "../../AppStateHandler";
import Section from "../../components/Section";
import { AppStateType, ScreenState } from "../../Types";
import "./NewVersionModelScreen.css";

interface NewVersionModelScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class NewVersionModelScreen extends React.Component<NewVersionModelScreenProps> {
  render() {
    return (
      <div id="NewVersionModelScreen">
        <BreadCrumbs
          data={[
            {
              title: "GrexManager",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Projecten",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
            {
              title: "Nieuwe versie",
            },
          ]}
        ></BreadCrumbs>

        <Section disabled={this.props.state.newVersionModelScreen.loadingForm}>
          {(() => {
            if (this.props.state.newVersionModelScreen.formError !== "") {
              return (
                <Alert
                  Error={true}
                  Message={this.props.state.newVersionModelScreen.formError}
                ></Alert>
              );
            }
          })()}
          <table className="NewVersionModelScreen-Table">
            <tbody>
              <tr>
                <td>Versienaam*</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Versienaam"
                    onChange={(newValue) => {
                      this.props.stateHandler.newVersionModelUpdateForm(
                        { versionName: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newVersionModelScreen.versionName}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Beschrijving</td>
                <td>
                  <TextInput
                    type={TextInputType.Text}
                    placeholder="Beschrijving"
                    onChange={(newValue) => {
                      this.props.stateHandler.newVersionModelUpdateForm(
                        { description: newValue },
                        this.props.updateStateCallback
                      );
                    }}
                    value={this.props.state.newVersionModelScreen.description}
                  ></TextInput>
                </td>
              </tr>
              <tr>
                <td>Fase</td>
                <td>
                  <DropDown
                    list={this.props.state.newVersionModelScreen.phases}
                    onChange={(newKey) => {
                      this.props.stateHandler.newVersionModelUpdateForm(
                        { phase: newKey },
                        this.props.updateStateCallback
                      );
                    }}
                    selectedKey={this.props.state.newVersionModelScreen.phase}
                  ></DropDown>
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <ButtonList
          data={[
            {
              title: "Opslaan",
              callback: () => {
                this.props.stateHandler.newVersionModelSave(
                  this.props.updateStateCallback
                );
              },
              disabled: this.props.state.newVersionModelScreen.loadingForm,
            },
            {
              title: "Annuleren",
              callback: () => {
                this.props.stateHandler.changeScreen(
                  ScreenState.Projects,
                  this.props.updateStateCallback
                );
              },
            },
          ]}
        ></ButtonList>
      </div>
    );
  }
}
