import { ApiGrexmanagerUserResetPasswordCreateRequest } from "@shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { checkPasswordPolicy } from "../../helperFunctions";
export class ResetPasswordUserStateHandler {
  EditUserResetPasswordForm(
    this: AppStateHandler,
    password: {
      newPassword?: string;
      newPasswordRepeat?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (password.newPassword !== undefined) {
      this.state.EditUser.newPassword = password.newPassword;

      checkPasswordPolicy(
        this.state.PasswordPolicy.newPasswordRequirements,
        this.state.EditUser.newPassword,
        this.state.PasswordPolicy.notAppliedRequirements
      );
    }
    if (password.newPasswordRepeat !== undefined) {
      this.state.EditUser.newPasswordRepeat = password.newPasswordRepeat;
      this.EditUserVlaidateNewPasswordRepeat();
    }
    callback(this.state);
  }

  EditUserVlaidateNewPasswordRepeat(this: AppStateHandler) {
    if (
      this.state.EditUser.newPassword !== this.state.EditUser.newPasswordRepeat
    ) {
      this.state.EditUser.newPasswordError = "Wachtwoorden komen niet overeen";
      return false;
    } else {
      this.state.EditUser.newPasswordError = "";
      return true;
    }
  }

  EditUserChangePassword(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (this.EditUserVlaidateNewPasswordRepeat()) {
      if (this.state.PasswordPolicy.notAppliedRequirements.length > 0) {
        this.state.EditUser.newPasswordError =
          "Wachtwoord voldoet niet aan de eisen";
        callback(this.state);
        return;
      }
      const Request: ApiGrexmanagerUserResetPasswordCreateRequest = {
        id: this.state.EditUser.id,
        grexUserResetPassword: {
          newPassword: this.state.EditUser.newPassword,
        },
      };

      this.userAdminApi
        .apiGrexmanagerUserResetPasswordCreate(Request)
        .then(() => {
          this.state.EditUser.newPassword = "";
          this.state.EditUser.newPasswordRepeat = "";
          this.state.EditUser.newPasswordError = "";
          this.state.EditUser.newPasswordinfo =
            "Het wachtwoord is succesvol gewijzigd";
          callback(this.state);
          this.changeScreen(ScreenState.EditUser, callback);
        })
        .catch((error) => {
          // Could not save new password
          console.log(error);
          this.state.EditUser.newPasswordError =
            "Fout bij het opslaan van het nieuwe wachtwoord.";
          callback(this.state);
          setTimeout(() => {
            this.state.EditUser.newPasswordError = "";
            callback(this.state);
          }, 5000);
        });
    }
  }
}
