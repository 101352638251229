import Button from "@shared/components/Button";
import SectionTitle from "@shared/components/SectionTitle";
import React from "react";
import { FiLogOut } from "react-icons/fi";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, LoginState } from "../../Types";
import "./NoneScreen.css";

interface LogoutScreenProps {
  state: AppStateType;
  loginstate: LoginState;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}
export default class NoneScreen extends React.Component<LogoutScreenProps> {
  render() {
    return (
      <div id="NoneScreen-Container">
        <div id="NoneScreen-Section">
          <div id="NoneScreen-TextHolder">
            <SectionTitle>GrexManager</SectionTitle>
            <div id="NoneScreen-Text">
              Bedankt voor het aanmelden.
              <br />
              Uw account moet wel nog geaccordeerd worden.
              <br />
              Uw administrator is hiervan op de hoogte gebracht.
              <br />
              <br />
              Met vriendelijke groet,
              <br />
              <br />
              het GrexManager Team
            </div>
            <Button
              onClick={() => {
                this.props.stateHandler.logout(this.props.updateStateCallback);
              }}
            >
              <FiLogOut /> Uitloggen
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
